<template>
  <v-list-item-title :title="title">{{title}}</v-list-item-title>
</template>

<script>
  export const address_individual = (contact) => {
    let name = `${contact.firstName} ${contact.lastName}`;
    if (contact.pre_nominal_title) {
      name = `${contact.pre_nominal_title} ${name}`;
    }
    if (contact.post_nominal_title) {
      name += `, ${contact.post_nominal_title}`;
    }
    return name;
  };

  export const address_org = (contact) => {
    let name = contact.org ? contact.org.name : contact.name;
    if (contact.org && contact.org.unit1) {
      name += ` - ${contact.org.unit1}`;

    } else if (contact.firstName && contact.lastName) {
      name += ` - ${address_individual(contact)}`;
    }
    return name;
  };

  export const address = (contact) => {
    let name = contact.name;
    if (contact.type === 'individual') {
      name = address_individual(contact);
    } else if (contact.type === 'org') {
      name = address_org(contact);
    }
    return name;
  };

  export const title = (contact = {}, type) => {
    if (type === 'address') {
      return address(contact);
    }
    return contact.name;
  };

  export default {
    name: 'ContactTitle',

    props: {
      contact: {
        type: Object,
        default: () => ({})
      },
      type: {
        type: String,
        default: ''
      }
    },

    computed: {
      title() {
        return title(this.contact, this.type);
      }
    }
  };
</script>
