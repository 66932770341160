<template>
  <div class="d-flex flex-column justify-center align-center">
    <div v-if="!inbox" class="text--accent text-center w-100">
      <div v-if="loading">
        <v-progress-circular indeterminate color="accent" class="mb-2"></v-progress-circular>
        <p>Postfach wird geladen ...</p>
      </div>
      <div v-if="!loading && error" class="text--error">
        <div v-if="error === 404">
          <p>
            Postfach nicht gefunden.<br>
            <small>
              Entweder funktioniert der von Ihnen angeklickte Link nicht oder das Postfach wurde entfernt.
            </small>
          </p>
          <v-btn id="back" depressed color="primary" small :to="{name: 'inboxes'}">
            Zu meinen Postfächern
          </v-btn>
        </div>
        <div v-else>
          <p>Postfach konnte nicht geladen werden.</p>
          <div v-if="error === 500 || error === true">
            <v-btn depressed color="primary" small @click="refresh">Erneut versuchen</v-btn>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="w-100">

      <v-toolbar ref="toolbar" flat>
        <TopbarNavigation
          drawer
          :drawer-open="drawerOpen"
          drawer-name="Postfächer"
        />

        <v-toolbar-title class="mr-2" style="background-color: transparent;">
          {{title}}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$options.name === 'InboxUpdate'"
          depressed
          color="primary"
          :loading="saveLoading"
          :disabled="saveDisabled"
          @click="save"
        >Speichern</v-btn>
      </v-toolbar>

      <v-container id="page-inbox-create">
        <v-alert v-if="saveError" type="error" text>
          Postfach konnte nicht gespeichert werden.<br>
          Bitte versuchen Sie es später noch einmal.
        </v-alert>

        <v-alert v-if="incomplete" type="error" text>
          <h4 class="text--primay-text">Unvollständiges Postfach.</h4>
          <template v-if="incomplete">
            Weisen Sie dem Postfach zumindest eine verifizierte Adresse zu<template v-if="!unverified.addresses">.</template>
            <div v-if="unverified.addresses">
              oder verifizieren Sie
              <template v-if="inbox.addresses.length === 1">die zugewiesenen Adresse.</template>
              <template v-else>zumindest eine der zugewiesenen Adressen.</template>
              <br>
              <a @click="_showVerificationInfo">
                Weitere Informationen zur Verifizierung von Adressen
              </a>
            </div>
          </template>
        </v-alert>

        <v-alert v-if="unverified" type="warning" icon="fas fa-info-circle" text>
          <h4 class="text--primay-text">
            Postfach enthält{{unverified.length > 1 ? ` ${unverified.length}` : ''}} nicht verifizierte Kontaktdaten.
          </h4>
          Verifizieren Sie nicht bestätigte Kontaktdaten
          um alle Einstellungen wie gewünscht nutzen zu können.<br>
          <a @click="_showVerificationInfo">
            Weitere Informationen zur Verifizierung von Kontaktdaten
          </a>
        </v-alert>

        <v-form v-if="inbox" ref="form" :readonly="saveLoading" lazy-validation @submit="_submit">
          <v-expansion-panels v-model="expanded" accordion mandatory>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="subtitle-2">Allgemein</span>

                <template #actions>
                  <v-icon>far fa-angle-down</v-icon>
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-text-field
                  ref="name-focus"
                  v-model="inbox.name"
                  :rules="[rules.required]"
                  placeholder="Name eingeben"
                  label="Name *"
                  outlined
                  dense
                ></v-text-field>

                <h5 class="mb-3">Farbe auswählen</h5>

                <ColorSelector v-model="inbox.color" />

                <div class="d-flex">
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="$options.name === 'InboxCreate'"
                    depressed
                    color="primary"
                    :loading="saveLoading"
                    @click="save"
                  >Erstellen und weiter</v-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel :disabled="stepsDisabled">
              <v-expansion-panel-header>
                <span class="subtitle-2">
                  <span :class="{'text--error': incomplete, 'text--warning': !incomplete && unverified.addresses}">
                    Adressenzuweisung ({{inbox.addresses | settingLength}})
                  </span>
                </span>

                <template #actions>
                  <v-icon>far fa-angle-down</v-icon>
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-alert type="info" text>
                  Legen Sie hier die Postadressen an, die diesem Postfach zugewiesen werden sollen.<br>
                  <strong>Wichtig:</strong> Die Postadresse sollte genau so angelegt werden,
                  wie Ihre Briefe an Sie adressiert sind.
                </v-alert>
                <Contacts v-model="inbox.addresses" :inbox="inbox" type="address" multiple>
                  <template #list-item-append="props">
                    <small
                      v-if="inbox.sender_data && inbox.sender_data.address && inbox.sender_data.address.id === props.contact.id"
                      class="ml-2"
                    >
                      <a @click="$router.replace({query: {...$route.query, step: 5}})">Absenderadresse</a>
                    </small>
                  </template>
                </Contacts>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- <v-expansion-panel :disabled="stepsDisabled">
              <v-expansion-panel-header>
                <span class="subtitle-2">
                  <span :class="{'text--warning': unverified.emails || unverified.phones}">
                    Alternative Empfangsdaten ({{altReceiverData | settingLength}})
                  </span>
                </span>

                <template v-slot:actions>
                  <v-icon>far fa-angle-down</v-icon>
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-alert type="info" text>
                  Wenn Sie alternative Empfangsdaten angeben, können Sie auch
                  Dateien von Versendern erhalten die keine Postadresse sondern
                  z.B. eine Ihrer E-Mail Adressen oder Telefonnummern kennen.
                </v-alert>

                <h4 class="mb-3">E-Mail Adressen</h4>
                <Contacts
                  v-model="inbox.emails"
                  :inbox="inbox"
                  type="email"
                  multiple
                  class="ml-4" />

                <h4 class="mb-3">Telefonnummern</h4>
                <Contacts
                  v-model="inbox.phones"
                  :inbox="inbox"
                  type="phone"
                  multiple
                  class="ml-4" />
              </v-expansion-panel-content>
            </v-expansion-panel> -->

            <v-expansion-panel :disabled="stepsDisabled">
              <v-expansion-panel-header>
                <span class="subtitle-2">Berechtigungen ({{inbox.groups | settingLength}})</span>

                <template #actions>
                  <v-icon>far fa-angle-down</v-icon>
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <Groups v-model="inbox.groups" />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel :disabled="stepsDisabled">
              <v-expansion-panel-header>
                <span class="subtitle-2">
                  <span :class="{'text--warning': unverified.notifications}">
                    Benachrichtigungen ({{inbox.notifications | notificationsLength}})
                  </span>
                </span>

                <template #actions>
                  <v-icon>far fa-angle-down</v-icon>
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <Notifications v-model="inbox.notifications" :inbox="inbox" />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="$options.name === 'InboxUpdate'">
              <v-expansion-panel-header>
                <span class="subtitle-2">
                  <span :class="{'text--warning': unverified.sender_data}">
                    Erweiterte Einstellungen
                  </span>
                </span>

                <template #actions>
                  <v-icon>far fa-angle-down</v-icon>
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <template v-if="inbox.sender_data">
                  <h4 class="mb-3">Absenderinformationen</h4>
                  <InboxSenderFields :inbox="inbox" class="ml-4" />
                </template>

                <h4 class="mb-3">Postfach entfernen</h4>

                <div class="ml-4">
                  <v-btn color="error" depressed @click="$refs.delete.open()">
                    <v-icon small class="mr-2">fal fa-trash-alt</v-icon> Postfach entfernen
                  </v-btn>
                  <InboxDelete ref="delete" :inbox="inbox" />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>

        <div v-if="$options.name === 'InboxUpdate'" class="d-flex justify-end mt-4">
          <v-btn depressed class="mr-2" :to="{name: 'inboxes'}">Abbrechen</v-btn>

          <v-btn
            depressed
            color="primary"
            :loading="saveLoading"
            :disabled="saveDisabled"
            @click="save"
          >Speichern</v-btn>
        </div>
      </v-container>
    </div>

    <InboxVerificationInfo />
  </div>
</template>

<script>
  import TopbarNavigation from '../components/TopbarNavigation';
  import ColorSelector from '../components/ColorSelector';
  import Contacts from '../components/contacts/Contacts';
  import Groups from '../components/Groups';
  import Notifications from '../components/notifications/Notifications';
  import InboxSenderFields from '../components/inbox/InboxSenderFields';
  import InboxDelete from '../components/inbox/InboxDelete';
  import InboxVerificationInfo from '../components/inbox/InboxVerificationInfo';
  import {incomplete, unverified} from '../utils/inbox';
  import {getRoute, getRouteTitle} from '../utils/navigation';
  import * as rules from '../utils/rules';
  import eventBus from '../utils/event-bus';

  export const inbox_default = {
    name: '',
    color: '',
    addresses: [],
    emails: [],
    phones: [],
    groups: [],
    notifications: [],
    sender_data: {}
  };

  export default {
    name: 'InboxCreate',

    components: {
      TopbarNavigation,
      ColorSelector,
      Contacts,
      Groups,
      Notifications,
      InboxSenderFields,
      InboxDelete,
      InboxVerificationInfo
    },

    filters: {
      settingLength(value) {
        return Array.isArray(value) ? value.length : 0;
      },
      notificationsLength(notifications) {
        if (!Array.isArray(notifications)) {
          return 0;
        }
        return notifications.filter((notification) => notification.enabled).length;
      }
    },

    props: {
      drawerOpen: {
        type: Boolean,
        default: false
      },
      step: { // router
        type: Number,
        default: 1
      }
    },

    data() {
      return {
        title: '',
        saveDisabled: false,
        expanded: 0,
        stepsDisabled: true,
        saveLoading: false,
        saveError: false,
        inbox: {...inbox_default},
        inbox_initial: {...inbox_default},
        unverified: false,
        rules
      };
    },

    computed: {
      incomplete: function() {
        if (this.$options.name !== 'InboxUpdate') {
          return false;
        }
        return incomplete(this.inbox);
      },

      altReceiverData: function() {
        let {emails, phones} = this.inbox;
        return [].concat(emails, phones);
      }
    },

    watch: {
      inbox() {
        this.saveError = false;
      },

      step(step) {
        this.expanded = step ? step - 1 : 0;
      },

      expanded(index) {
        const {$route} = this;
        const step = index + 1;
        if (parseInt($route.query.step) !== step) {
          this.$router.replace({query: {...$route.query, step}});
        }
      }
    },

    created() {
      const {$options} = this;
      const route = getRoute($options.filters.paramCase($options.name));
      this.title = getRouteTitle(route);

      this.$watch('inbox', (inbox) => this.unverified = unverified(inbox), {deep: true});
    },

    mounted() {
      this.$options.watch.step.call(this, this.step);
    },

    methods: {
      async save() {
        if (this.saveLoading) {
          return;
        }

        this.saveError = false;

        const valid = this.$refs.form.validate();
        if (!valid) {
          return;
        }

        this.saveLoading = true;

        try {
          const componentName = this.$options.name;

          const body = JSON.parse(JSON.stringify(this.inbox));

          if (this.$options.name === 'InboxCreate') {
            // Clear empty arrays & objects
            for (const key in body) {
              if (Array.isArray(body[key]) && !body[key].length ||
                typeof body[key] === 'object' && !Object.keys(body[key]).length) {
                delete body[key];
              }
            }
          }

          const {data} = await this.$apiOperation(componentName, {
            parameters: {inboxId: this.inbox.id},
            requestBody: body
          });

          this.saveLoading = false;
          this.reset();

          if (this.$options.name === 'InboxCreate') {
            this.$parent.refresh();
            this.$router.push({
              name: 'inbox-update',
              params: {inboxId: data.id},
              query: {step: 2}
            });
          } else {
            this.$router.push({name: 'inboxes'});
          }

        } catch (saveError) {
          this.saveLoading = false;
          this.saveError = true;
        }
      },

      reset() {
        this.$refs.form.reset();
        this.inbox = {...this.inbox_initial};
      },

      _submit(e) {
        e.preventDefault();
        this.save();
      },

      _showVerificationInfo() {
        eventBus.$emit('open-inbox-verification-info');
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "../assets/scss/variables";

  h4 {
    color: $primary-light;
  }

  #back.v-btn--active::before {
    background-color: transparent;
  }

  .v-alert a {
    text-decoration: underline;
  }
</style>
