import Vue from 'vue';
import XcaAppCore from 'xca-app-core';
import vuetifyLocaleDe from 'vuetify/es5/locale/de';
import VueCase from 'vue-case';
import VueSignaturePad from 'vue-signature-pad';
import '@fortawesome/fontawesome-pro/css/all.css';

import appConfig from './app.config.json';
import routes from './routes';
import storeModules from './store-modules';
import locales from './locales';
import utilsMixin from './mixins/utils';
import App from './App';


const xcaAppCoreOptions = {
  appConfig,
  routes,
  router: {userStartPage: '/inboxes'},
  storeModules,
  locales,
  language: 'de',
  vuetifyConfig: {
    icons: {
      iconfont: 'fa'
    },
    lang: {
      locales: {de: vuetifyLocaleDe},
      current: 'de'
    }
  }
};

Vue.mixin(utilsMixin);

Vue.use(VueCase);
Vue.use(VueSignaturePad);

// -----------------------------------------------------------------------------
// Vue Instance
// -----------------------------------------------------------------------------
new Vue({
  ...XcaAppCore(Vue, xcaAppCoreOptions), // Initiate XcaAppCore
  el: document.getElementById('app'),
  render: (h) => h(App)
});
