<template>
  <router-link
    class="router-link d-block"
    :to="{name: 'documents', params: {inboxId: inbox.id}}"
    :class="{'router-link-active': $route.params.inboxId === inbox.id}"
  >
    <v-card class="mb-4" hover>
      <div class="color" :style="{backgroundColor: inbox.color}"></div>
      <v-card-text>
        <div class="d-flex align-center">
          <div class="flex">
            <v-card-title :title="inbox.name" class="text--primary px-0 pt-0 pb-1">{{inbox.name}}</v-card-title>
            <v-card-subtitle class="py-0 pl-0 mt-0" :class="{'font-weight-medium text--info': inbox.unread > 0}">
              {{inbox.unread}}
              ungelesene<template v-if="inbox.unread === 1">s</template>
              Dokument<template v-if="inbox.unread === 0 || inbox.unread > 1">e</template>
            </v-card-subtitle>
          </div>
          <div class="d-flex align-center">
            <v-btn
              v-if="incomplete"
              :to="{name: 'inbox-update', params: {inboxId: inbox.id}, query: {step: 2}}"
              title="Unvollständiges Postfach"
              color="error"
              icon
            >
              <v-icon>fas fa-exclamation-triangle</v-icon>
            </v-btn>
            <v-btn
              v-else-if="unverified"
              :to="{name: 'inbox-update', params: {inboxId: inbox.id}, query: {step: 2}}"
              title="Nicht verifizierte Kontaktdaten"
              color="warning"
              icon
            >
              <v-icon>fas fa-info-circle</v-icon>
            </v-btn>
            <InboxActions :inbox="inbox" />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </router-link>
</template>

<script>
  import {incomplete, unverified} from '../../utils/inbox';
  import InboxActions from './InboxActions';

  export default {
    name: 'InboxItem',

    components: {
      InboxActions
    },

    props: {
      inbox: {
        type: Object,
        default: () => ({})
      }
    },

    computed: {
      incomplete: function() {
        return incomplete(this.inbox);
      },

      unverified: function() {
        return unverified(this.inbox);
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/variables";

  .v-card {
    overflow: hidden;

    .color {
      position: absolute;
      left: 0;
      top: 0;
      width: 3px;
      height: 100%;
      background-color: $accent-light;
    }
  }
</style>
