<template>
  <v-dialog v-model="opened" :persistent="loading" :max-width="maxWidth" scrollable>
    <v-card>
      <v-card-title class="headline">Postfach "{{inbox.name}}" entfernen?</v-card-title>
      <v-alert v-if="error" type="error" text>
        Postfach konnte nicht entfernt werden.<br>
        Bitte versuchen Sie es später noch einmal.
      </v-alert>
      <v-card-text class="pb-0">
        Postfach und alle enthaltenen Dokumente können nach dem Entfernen nicht wiederhergestellt werden.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed :disabled="loading" @click="close">
          Abbrechen
        </v-btn>
        <v-btn color="error" depressed :loading="loading" @click="this.delete">
          Entfernen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import DialogMixin from '../../mixins/dialog-mixin.js';

  export default {
    name: 'DocumentDelete',

    mixins: [
      DialogMixin
    ],

    props: {
      inbox: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        opened: false, // DialogMixin
        loading: false,
        error: false
      };
    },

    watch: {
      opened(opened) {
        if (!opened) {
          this.error = false;
        }
      }
    },

    methods: {
      async delete() {
        this.error = false;
        this.loading = true;

        try {
          await this.$apiOperation('InboxDelete', {parameters: {
            inboxId: this.inbox.id
          }});

          this.loading = false;
          this.close(); // DialogMixin

          if (this.$route.name !== 'inboxes') {
            this.$router.push({name: 'inboxes'});
          } else {
            this.$route.matched[0].instances.default.refresh();
          }

        } catch (error) {
          this.loading = false;
          this.error = true;
        }
      }
    }
  };
</script>
