<template>
  <div>
    <v-toolbar ref="toolbar" flat>
      <TopbarNavigation
        drawer
        :drawer-open="drawerOpen"
        drawer-name="Dokumente"
      />

      <v-toolbar-title class="mr-2" style="background-color: transparent;">
        {{document.name}}
      </v-toolbar-title>

      <slot v-if="toolbarDense" name="toolbar"></slot>
      <slot v-else slot="extension" name="toolbar"></slot>
    </v-toolbar>

    <v-container id="page-document" ref="container" fluid>
      <slot :columns="contentColumns"></slot>
    </v-container>
  </div>
</template>

<script>
  import {ResizeSensor} from 'css-element-queries';
  import TopbarNavigation from '../TopbarNavigation';

  export default {
    name: 'DocumentViewContainer',

    components: {
      TopbarNavigation
    },

    props: {
      document: {
        type: Object,
        default: () => ({})
      },
      drawerOpen: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        toolbarDense: true,
        contentColumns: false
      };
    },

    async mounted() {
      // Wait until elements are created.
      await new Promise((resolve) => this.$nextTick(() => resolve()));

      const toolbar = this.$refs.toolbar.$el;
      new ResizeSensor(toolbar, () => {
        this.toolbarDense = toolbar.clientWidth >= 600;
      });

      const container = this.$refs.container;
      new ResizeSensor(container, () => {
        this.contentColumns = container.clientWidth >= 800;
      });
    }
  };
</script>

<style lang="scss">
  @import '@/assets/scss/variables';

  #page-document {
    strong,
    dt {
      font-weight: 500;
    }

    li {
      list-style-type: none;
    }

    .sender-detail a:not(.v-btn) {
      color: $primary-text;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
</style>
