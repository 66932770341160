<template>
  <DocumentViewContainer
    :drawer-open="drawerOpen"
    :document="document"
  >
    <DocumentToolbarExtension
      slot="toolbar"
      :document="document"
      delete
      done
      @delete="$refs.delete.open();"
      @done="$refs.done.open();"
    />

    <template #default="contentProps">
      <p v-if="document.status === 'SENT'">
        Sie haben uns beauftragt
        <template v-if="document.created">
          am <strong>{{document.created | moment('L [um] LT [Uhr]')}}</strong>
        </template>
        {{$tc('documents.following_documents', document.files)}}
        <template v-if="document.receiver_address && document.receiver_address.name">
          an den geprüften Empfänger <strong>"{{document.receiver_address.name}}"</strong>
        </template>
        zuzustellen.
      </p>
      <h2 v-else-if="document.status === 'DRAFT'" class="text--warning">
        Entwurf
      </h2>

      <v-row>
        <v-col :cols="contentProps.columns ? '7': '12'">
          <DocumentFiles :document="document" />

          <v-row v-if="document.description">
            <v-col>
              <h3 class="mb-6">Information für Empfänger</h3>
              <v-card class="v-card--no-shadow">
                <v-card-text class="text--primay-text">{{document.description}}</v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row v-if="document.events && document.events.length">
            <v-col>
              <DocumentActivity :document="document" />
            </v-col>
          </v-row>
        </v-col>

        <v-col :cols="contentProps.columns ? '5': '12'">
          <h3 class="mb-6">Empfänger</h3>
          <v-card class="sender-detail v-card--no-shadow px-6 py-6 pt-1">
            <dl>
              <template v-if="document.source">
                <dt class="mt-5 mb-1">Quelle/Herkunft</dt>
                <dd>{{document.source}}</dd>
              </template>

              <template v-if="document.confirmation">
                <dt class="mt-5 mb-1">Nachricht gilt als erfolgreich zugestellt, wenn</dt>
                <dd>
                  {{$t(`documents.confirmation.${document.confirmation}`) | toLowerCase}}.
                </dd>
              </template>

              <template v-if="document.receipt_required !== 'NONE'">
                <dt class="mt-5 mb-1">Unterschrift</dt>
                <dd>
                  <template v-if="!document.receipt_date">
                    Dokument wurde noch nicht unterzeichnet.
                  </template>
                  <template v-else>
                    Dokument wurde
                    von {{document.receipt_name}}
                    am {{document.receipt_date | moment('L [um] LT [Uhr]')}}
                    unterzeichnet.<br>
                    <v-btn
                      small
                      outlined
                      :href="`/api/inboxes/${document.inboxId}/documents/${document.id}/receipt`"
                      target="_blank"
                    >
                      <v-icon small class="mr-2">fas fa-signature</v-icon>
                      Unterschrift ansehen
                    </v-btn>
                  </template>
                </dd>
              </template>

              <template v-if="document.receiver_address && document.receiver_address.name">
                <dt class="mt-5 mb-1">Name</dt>
                <dd>{{document.receiver_address.name}}</dd>
              </template>

              <template v-if="document.sender_metadata && document.sender_metadata.description">
                <dt class="mt-5 mb-1">Beschreibung</dt>
                <dd>{{document.sender_metadata.description}}</dd>
              </template>

              <template v-if="document.receiver_address && (document.receiver_address.address || document.receiver_address.raw)">
                <dt class="mt-5 mb-1">Empfänger Adresse</dt>
                <dd v-if="document.receiver_address.address">
                  {{document.receiver_address.address.street}}<br>
                  {{document.receiver_address.address.postcode}} {{document.receiver_address.address.city}}<br>
                  {{$t(`countries.${document.receiver_address.address.country_code.toLowerCase()}`)}}
                </dd>
                <dd v-else-if="document.receiver_address.raw">
                  <template v-for="(address, index) in document.receiver_address.raw">
                    {{address}}<br :key="index">
                  </template>
                </dd>
              </template>

              <template v-if="document.receiver_metadata && document.receiver_metadata.contactPerson">
                <dt class="mt-5 mb-1">Ihr Ansprechpartner</dt>
                <dd>{{document.receiver_metadata.contactPerson}}</dd>
              </template>

              <template v-if="document.receiver_phone || document.receiver_email || (document.receiver_metadata && document.receiver_metadata.website)">
                <dt class="mt-5 mb-1">Kontaktinformationen</dt>
                <dd>
                  <ul class="pl-0">
                    <li v-if="document.receiver_phone" class="mb-1">
                      <v-icon small>far fa-phone-alt</v-icon>&nbsp;
                      <a :href="'tel:' + document.receiver_phone.phone">{{document.receiver_phone.phone}}</a>
                    </li>
                    <li v-if="document.receiver_email" class="mb-1">
                      <v-icon small>far fa-envelope</v-icon>&nbsp;
                      <a :href="'mailto:' + document.receiver_email.email">{{document.receiver_email.email}}</a>
                    </li>
                    <li v-if="document.receiver_metadata && document.receiver_metadata.website" class="mb-1">
                      <v-icon small>far fa-globe-europe</v-icon>&nbsp;
                      <a :href="document.receiver_metadata.website" target="_blank">{{_domain(document.receiver_metadata.website)}}</a>
                    </li>
                  </ul>
                </dd>
              </template>
            </dl>
          </v-card>
        </v-col>
      </v-row>

      <DocumentDelete ref="delete" :document="document" />
      <DocumentDone ref="done" :document="document" @done="refresh() && $parent.refresh()" />
    </template>
  </DocumentViewContainer>
</template>

<script>
  import DocumentViewContainer from './DocumentViewContainer';
  import DocumentToolbarExtension from './DocumentToolbarExtension';
  import DocumentFiles from './DocumentFiles';
  import DocumentActivity from './DocumentActivity';
  import DocumentDelete from './DocumentDelete';
  import DocumentDone from './DocumentDone';

  export default {
    name: 'DocumentViewReceived',

    components: {
      DocumentViewContainer,
      DocumentToolbarExtension,
      DocumentFiles,
      DocumentActivity,
      DocumentDelete,
      DocumentDone
    },

    filters: {
      toLowerCase(value = '') {
        return value.toLowerCase();
      }
    },

    props: {
      drawerOpen: {
        type: Boolean,
        default: false
      },
      document: {
        type: Object,
        default: () => ({})
      }
    },

    methods: {
      delete() {
        this.$refs.delete.open();
      },

      _domain(url) {
        try {
          return new URL(url).hostname;
        } catch (error) {
          return url;
        }
      }
    }
  };
</script>

<style lang="scss">
  #back.v-btn--active::before {
    background-color: transparent;
  }

  #page-document {
    strong,
    dt {
      font-weight: 500;
    }

    li {
      list-style-type: none;
    }
  }
</style>
