<template>
  <div class="inboxes-container d-flex">
    <v-navigation-drawer
      v-show="!(mediumScreen && !drawerOpen)"
      ref="drawer"
      v-model="drawerOpen"
      class="flex-shrink-0"
      :width="$vuetify.breakpoint.smAndUp ? 450 : '100%'"
      :style="{'height': mediumScreen ? 'auto' : '100vh'}"
      :absolute="!mediumScreen"
      :temporary="!mediumScreen"
      :permanent="mediumScreen || !detailPage"
    >
      <v-toolbar flat>
        <TopbarNavigation />

        <v-toolbar-title style="background-color: transparent;">
          Meine Postfächer
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          icon
          depressed
          title="Postfach erstellen"
          class="mr-1"
          :to="{name: 'inbox-create'}"
        >
          <v-icon small>far fa-plus</v-icon>
        </v-btn>
      </v-toolbar>

      <v-container
        id="page-inboxes-list"
        :class="{'d-flex align-stretch': !inboxes.length}"
        fluid
      >
        <div v-if="!inboxes.length" class="text--accent d-flex align-center justify-center text-center w-100">
          <div v-if="loading">
            <v-progress-circular
              indeterminate
              color="accent"
              class="mb-2"
            ></v-progress-circular>
            <p>Postfächer werden geladen ...</p>
          </div>
          <div v-if="!loading && error" class="text--error">
            <p>Postfächer konnten nicht geladen werden.</p>
            <v-btn depressed color="primary" small @click="refresh">Erneut versuchen</v-btn>
          </div>
          <div v-if="!loading && !error">
            Keine Postfächer vorhanden.
          </div>
        </div>

        <InboxItem
          v-for="(inbox, index) in inboxes"
          :key="index"
          :inbox="inbox"
          @click="!mediumScreen ? drawerOpen = false : null"
        />
      </v-container>
    </v-navigation-drawer>

    <div class="flex d-flex align-stretch">
      <router-view
        v-if="detailPage"
        :drawer-open="drawerOpen"
        class="w-100 align-stretch"
      />

      <v-container v-else id="page-no-inbox" class="d-flex flex-column justify-center align-center" fluid>
        <v-icon class="mb-5">fad fa-inbox</v-icon>
        <div class="text-center">
          Kein Postfach ausgewählt.
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
  import ListMixin from '../mixins/list-mixin';
  import TopbarNavigation from '../components/TopbarNavigation';
  import InboxItem from '../components/inbox/InboxItem';

  export default {
    name: 'Inboxes',

    components: {
      TopbarNavigation,
      InboxItem
    },

    mixins: [
      ListMixin({
        items: 'inboxes',
        id: 'inboxId',
        operationId: 'InboxesList'
      })
    ],

    data() {
      return {
        drawerOpen: this.$vuetify.breakpoint.mdAndUp
      };
    },

    computed: {
      detailPage() {
        return !!(this.inboxId || ['inbox-create', 'inbox-update'].includes(this.$route.name));
      },

      mediumScreen() {
        return this.$vuetify.breakpoint.mdAndUp;
      }
    },

    watch: {
      detailPage(detailPage, prevDetailPage) {
        if (!detailPage && prevDetailPage) {
          this.drawerOpen = true;
        }
      }
    },

    mounted() {
      window.addEventListener('toggle-drawer', this.toggleDrawer);
    },

    destroyed() {
      window.removeEventListener('toggle-drawer', this.toggleDrawer);
    },

    methods: {
      toggleDrawer() {
        this.drawerOpen = !this.drawerOpen;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .inboxes-container {
    min-height: 100vh;
  }

  .v-navigation-drawer {
    background-color: #f8f9fb;
    z-index: 5;
  }

  #page-no-inbox {
    min-height: 100vh;

    &,
    .v-icon {
      color: $accent-light;
    }

    .v-icon {
      font-size: 220px;
    }
  }
</style>
