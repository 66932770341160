<template>
  <v-dialog v-model="opened" :persistent="loading" :max-width="maxWidth" scrollable>
    <v-card>
      <v-card-title class="headline">{{$t(`contacts.${type}.single`)}} hinzufügen</v-card-title>
      <v-alert v-if="error" type="error" text>
        {{$t(`contacts.${type}.single`)}} konnte nicht hinzugefügt werden.<br>
        <template v-if="typeof error === 'string'">{{error}}</template>
        <ul v-else-if="Array.isArray(error)" class="pl-5">
          <li v-for="(err, i) in error" :key="i">{{err}}</li>
        </ul>
        <template v-else>Bitte versuchen Sie es später noch einmal.</template>
      </v-alert>
      <v-alert type="info" text>
        Nach dem Hinzufügen der {{$t(`contacts.${type}.single`)}}
        erhalten Sie eine Verifizierung per {{$t(`contacts.${type}.verifyType`)}}.<br>
        Bitte öffnen Sie diese Nachricht und folgen Sie den Anweisungen.<br>
        <a @click="_showVerificationInfo">
          Weitere Informationen zur Verifizierung von Kontaktdaten
        </a>
      </v-alert>
      <v-card-text class="pb-0">
        <v-form
          v-if="form_active"
          ref="form"
          :readonly="loading"
          lazy-validation
          @submit="_submit"
        >
          <EmailFields v-if="type === 'email'" ref="fields" v-model="data" />
          <PhoneFields v-if="type === 'phone'" ref="fields" v-model="data" />
          <AddressFields v-if="type === 'address'" ref="fields" v-model="data" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <small class="theme--light v-label pl-4">* Pflichtfelder</small>
        <v-spacer></v-spacer>
        <v-btn depressed :disabled="loading"  @click="_cancel">
          Abbrechen
        </v-btn>
        <v-btn color="success" depressed :loading="loading" @click="create">
          Hinzufügen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import fieldComponents from './fields';
  import {errorMessages as address_errorMessages} from './fields/AddressFields';
  import DialogMixin from '../../mixins/dialog-mixin.js';
  import eventBus from '../../utils/event-bus';

  const errorMessages = {
    address: address_errorMessages
  };

  export default {
    name: 'ContactCreate',

    components: {
      EmailFields: fieldComponents.EmailFields,
      PhoneFields: fieldComponents.PhoneFields,
      AddressFields: fieldComponents.AddressFields
    },

    mixins: [
      DialogMixin
    ],

    props: {
      type: {
        type: String,
        default: 'email'
      },
      inbox: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        opened: false, // DialogMixin
        form_active: true,
        loading: false,
        error: false,
        data: undefined
      };
    },

    watch: {
      opened(opened) {
        if (!opened) {
          this.error = false;
          this.$nextTick(() => {
            const {form} = this.$refs;
            if (form) {
              form.resetValidation();
            }
          });
          return;
        }

        this.$nextTick(() => {
          const fields = this.$refs.fields;
          if (!fields) {
            return;
          }
          const field = fields.$refs['input-focus'];
          if (!field) {
            return;
          }
          const input = field.$el.querySelector('input');
          // https://github.com/vuetifyjs/vuetify/issues/6892#issuecomment-478832713
          input.addEventListener('focusin', (e) => e.stopPropagation());

          this.$nextTick(() => {
            input.focus();
            // input.setAttribute('autocomplete', 'off');
          });
        });
      }
    },

    methods: {
      async create() {
        if (this.loading) {
          return;
        }

        this.error = false;

        const valid = this.$refs.form.validate();
        if (!valid) {
          return;
        }

        const {type} = this;
        this.loading = true;

        try {
          const operationId = type.substring(0, 1).toUpperCase() + type.substring(1) + 'Create';

          const {data} = await this.$apiOperation(operationId, {
            parameters: {inboxId: this.inbox.id},
            requestBody: JSON.parse(JSON.stringify(this.data))
          });

          this.$emit('create', data.id);
          this.loading = false;
          this.close(); // DialogMixin
          this.reset();

        } catch (error) {
          this.loading = false;
          try {
            this.error = errorMessages[type](error.response);
          } catch (err) {
            this.error = true;
          }
        }
      },

      reset() {
        this.$refs.form.reset();
        this.form_active = false;
        this.$nextTick(() => {
          this.data = undefined;
          this.form_active = true;
        });
      },

      _submit(e) {
        e.preventDefault();
        this.create();
      },

      _cancel() {
        this.close(); // DialogMixin
        this.reset();
      },

      _showVerificationInfo() {
        eventBus.$emit('open-inbox-verification-info');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .v-alert {
    margin-bottom: 0;

    a {
      text-decoration: underline;
    }
  }

  small.v-label {
    font-size: 13px;
  }
</style>
