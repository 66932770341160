<template>
  <v-chip :color="color" outlined small @click.stop="_showVerificationInfo">
    <v-icon small class="mr-2 py-1">fas fa-info-circle</v-icon>
    {{text}}
  </v-chip>
</template>

<script>
  import eventBus from '../../utils/event-bus';


  export const texts = {
    SUCCESS: 'verifiziert',
    FAILED: 'Verifizierung fehlgeschlagen',
    PENDING: 'Verifizierung ausstehend',
    [undefined]: 'Verifizierung ausstehend'
  };


  export default {
    name: 'ContactStatus',

    props: {
      contact: {
        type: Object,
        default: () => ({})
      }
    },

    computed: {
      color() {
        switch (this.contact.status) {
          case 'SUCCESS':
            return 'success';

          case 'FAILED':
            return 'error';

          case 'PENDING':
          default:
            return 'warning';
        }
      },

      text() {
        return texts[this.contact.status];
      }
    },

    methods: {
      _showVerificationInfo() {
        eventBus.$emit('open-inbox-verification-info');
      }
    }
  };
</script>
