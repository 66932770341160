import {getRouteTitle} from '../utils/navigation';

const options_default = {
  item: 'item',
  id: 'itemId',
  operationId: 'ItemGet',
  parameters: {}
};

export default (options = {}) => {
  options = {...options_default, ...options};

  return {
    data() {
      return {
        [options.item]: null,
        error: false,
        documentTitle: '',
        request: null
      };
    },

    computed: {
      [options.id]() {
        return this.$route.params[options.id];
      },

      loading() {
        return !!this.request;
      }
    },

    watch: {
      [options.id](id) {
        if (id) {
          this.refresh();
        }
      },

      [options.item](item) {
        this._setDocumentTitle(item);
      },

      documentTitle(documentTitle) {
        this.$emit('documentTitle', documentTitle);
      }
    },

    async mounted() {
      if (this[options.id]) {
        await this.refresh();
      }
    },

    methods: {
      async refresh() {
        if (this.request) {
          await this.request;
          return this[options.item];
        }
        this.error = false;

        try {
          let {parameters} = options;
          parameters = typeof parameters === 'function' ? parameters.apply(this) : parameters;

          this.request = this.$apiOperation(options.operationId, {
            parameters: {
              [options.id]: this[options.id],
              ...parameters
            }
          });

          const response = await this.request;
          this[options.item] = response.data;

        } catch (error) {
          const status = error.response ? error.response.status : null;
          this.error = status || true;
          this[options.item] = null;
        }

        this.request = null;
        return this[options.item];
      },

      _setDocumentTitle(item) {
        const matched = this.$route.matched;
        const route = matched[matched.length - 1];
        let title = getRouteTitle(route);
        if (item && item.name) {
          title += ` "${item.name}"`;
        }
        this.documentTitle = title;
      }
    }
  };
};
