<template>
  <v-dialog v-model="opened" :persistent="loading" :max-width="maxWidth" scrollable>
    <v-card>
      <v-card-title class="headline">
        Dokument "{{document.name}}" auf erledigt setzen?
      </v-card-title>
      <v-alert v-if="error" type="error" text>
        Dokument konnte nicht auf erledigt gesetzt werden.<br>
        Bitte versuchen Sie es später noch einmal.
      </v-alert>
      <v-card-text class="pb-0">
        Der Status wird von <DocumentStatus small :document="{opened: true}" />
        auf <DocumentStatus small :document="{done: true}" /> gesetzt.
        <br>
        Dies ist nur für Sie sichtbar. Dadurch können Sie dieses Dokument
        markieren um zu wissen, dass Sie alles erledigt haben.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed :disabled="loading" @click="close">
          Abbrechen
        </v-btn>
        <v-btn color="success" depressed :loading="loading" @click="done">
          Ja
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import DialogMixin from '../../mixins/dialog-mixin.js';
  import DocumentStatus from './DocumentStatus';

  export default {
    name: 'DocumentDone',

    components: {
      DocumentStatus
    },

    mixins: [
      DialogMixin
    ],

    props: {
      document: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        opened: false, // DialogMixin
        loading: false,
        error: false
      };
    },

    watch: {
      opened(opened) {
        if (!opened) {
          this.error = false;
        }
      }
    },

    methods: {
      async done() {
        if (this.loading) {
          return;
        }

        this.error = false;
        this.loading = true;

        try {
          await this.$apiOperation('DocumentSetDone', {parameters: {
            documentId: this.document.id,
            inboxId: this.document.inboxId
          }});

          this.$emit('done');
          this.loading = false;
          this.close(); // DialogMixin

        } catch (error) {
          this.loading = false;
          this.error = true;
        }
      }
    }
  };
</script>
