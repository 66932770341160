<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" class="pr-sm-1">
        <xca-input-radio
          name="type"
          value="individual"
          icon="far fa-user"
          required
          :checked="data.type === 'individual'"
          @selected-value="data.type = $event"
        >
          Person
          <template #secondary>(Privatperson, Natürliche Person)</template>
        </xca-input-radio>
      </v-col>
      <v-col cols="12" sm="6" class="pl-sm-1">
        <xca-input-radio
          name="type"
          value="org"
          icon="far fa-building"
          required
          :checked="data.type === 'org'"
        >
          Organisation
          <template #secondary>(Unternehmen, Verein, Behörde)</template>
        </xca-input-radio>
      </v-col>
    </v-row>

    <template v-if="data.type === 'individual'">
      <p>Name</p>
      <NameFields v-model="data" />
    </template>
    <template v-else-if="data.type === 'org' && data.org">
      <p>Organisation</p>
      <v-text-field
        ref="input-focus"
        v-model="data.org.name"
        :rules="[rules.required]"
        type="text"
        name="org.name"
        label="Name der Organisation *"
        outlined
        dense
      ></v-text-field>

      <label>Adressieren an:</label>
      <v-row>
        <v-col cols="12" sm="6" class="pt-0 pr-sm-1">
          <xca-input-radio
            name="org_type"
            value="unit1"
            icon="far fa-briefcase"
            required
            small
            :checked="org_type === 'unit1'"
            @selected-value="org_type = $event"
          >Abteilung oder Projekt</xca-input-radio>
        </v-col>
        <v-col cols="12" sm="6" class="pt-0 pl-sm-1">
          <xca-input-radio
            name="org_type"
            value="individual"
            icon="far fa-user"
            required
            small
            :checked="org_type === 'individual'"
          >Person</xca-input-radio>
        </v-col>
      </v-row>

      <template v-if="org_type === 'unit1'">
        <v-text-field
          v-model="data.org.unit1"
          type="text"
          name="org.unit1"
          label="Abteilung oder Projekt"
          outlined
          dense
        ></v-text-field>
      </template>
      <NameFields v-else-if="org_type === 'individual'" v-model="data" />
    </template>

    <template v-if="data.address">
      <p>{{$t('contacts.address.single')}}</p>
      <v-text-field
        v-model="data.address.street"
        :rules="[rules.required]"
        type="text"
        name="address.street"
        label="Straße und Hausnummer *"
        outlined
        dense
      ></v-text-field>
      <!-- <v-text-field
        v-model="data.address.extension"
        type="text"
        name="address.extension"
        label="Adresszusatz"
        outlined
        dense
      ></v-text-field> -->
      <v-row>
        <v-col cols="12" sm="4" class="py-0 pr-sm-1">
          <v-text-field
            v-model="data.address.postcode"
            :rules="[rules.required, rules.postcode]"
            type="text"
            name="address.postcode"
            label="Postleitzahl *"
            outlined
            dense></v-text-field>
        </v-col>
        <v-col cols="12" sm="8" class="py-0 pl-sm-1">
          <v-text-field
            v-model="data.address.city"
            :rules="[rules.required]"
            type="text"
            name="address.city"
            label="Stadt *"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="12" sm="6" class="py-0 pr-sm-1">
          <v-text-field
            v-model="data.address.district"
            type="text"
            name="address.district"
            label="Bezirk"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="py-0 pl-sm-1">
          <v-text-field
            v-model="data.address.region"
            type="text"
            name="address.region"
            label="Bundesland"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row> -->
      <v-select
        v-model="data.address.country_code"
        :rules="[rules.required]"
        :items="[
          {text: 'Österreich', value: 'AT'},
          {text: 'Deutschland', value: 'DE'},
          {text: 'Schweiz', value: 'CH'}
        ]"
        name="address.country_code"
        label="Land *"
        outlined
        dense>
      </v-select>

      <v-text-field
        v-if="data.type === 'org'"
        v-model="data.taxNumber"
        :rules="[rules.required, rules.taxNumber(data.address.country_code)]"
        :placeholder="taxNumberPlaceholder"
        type="text"
        name="address.taxNumber"
        label="USt-IdNr. / VAT Nummer *"
        outlined
        dense></v-text-field>
    </template>
  </div>
</template>

<script>
  import {XcaInputRadio} from 'xca-app-components';
  import NameFields from './NameFields';
  import * as rules from '../../../utils/rules';

  export default {
    name: 'AddressFields',

    components: {
      XcaInputRadio,
      NameFields
    },

    props: {
      value: {
        type: Object,
        default: () => ({
          type: 'individual', // or 'org'
          pre_nominal_title: '',
          firstName: '',
          lastName: '',
          post_nominal_title: '',
          org: {
            name: '',
            unit1: ''
          },
          address: {
            street: '',
            postcode: '',
            city: '',
            country_code: 'AT'
            // ,district: '',
            // region: '',
            // extension: '',
            // pobox: ''
          },
          taxNumber: ''
        })
      }
    },

    data() {
      return {
        rules,
        org_type: 'unit1', // or 'individual'
        data: {
          org: {},
          address: {}
        }
      };
    },

    computed: {
      taxNumberPlaceholder() {
        const country_code = this.value.address.country_code;
        const country_placeholders = {
          AT: 'ATU 99999999',
          DE: 'DE 999999999',
          CH: 'CHE-123.456.788 TVA'
        };

        if (country_placeholders[country_code]) {
          return country_placeholders[country_code];
        }
        return Object.values(country_placeholders).join(', ');
      }
    },

    watch: {
      value() {
        if (!this._ignoreValueChange) {
          this._setData();
        }
      },

      data(data) {
        if (this._ignoreDataChange) {
          return;
        }
        const data_default = this.$options.data().data;

        this._ignoreDataChange = true;
        if (!data) {
          this.data = data_default;
        } else {
          this.data = {...data_default, ...this.data};
        }
        this.$nextTick(() => this._ignoreDataChange = false);
      }
    },

    created() {
      this._setData();
      this.$watch((self) => [self.data, self.org_type], ([data, org_type]) => {
        const value = {...this.$options.props.value.default, ...data};

        if (value.type === 'individual') {
          delete value.org;
          delete value.taxNumber;
        } else if (value.type === 'org') {
          if (org_type === 'individual') {
            delete value.org.unit1;
          } else if (org_type === 'unit1') {
            delete value.pre_nominal_title;
            delete value.firstName;
            delete value.lastName;
            delete value.post_nominal_title;
          }
        }

        this.$emit('input', value);
      }, {
        immediate: true,
        deep: true
      });
    },

    methods: {
      _setData() {
        const value = this.value;
        const value_default = this.$options.props.value.default;

        this._ignoreValueChange = true;
        if (value) {
          this.data = {...value_default, ...value};
        } else {
          this.data = {...value_default};
        }
        this.$nextTick(() => this._ignoreValueChange = false);
      }
    }
  };

  export const errorMessages = (response = {}) => {
    try {
      const messages = response.data.error.failedReasons.map((reason) => {
        switch (reason) {
          case 'E_TAX_NUMBER_INVALID_TAX_NUMBER':
            return 'Ungültige USt-IdNr. / VAT Nummer';

          case 'E_TAX_NUMBER_INVALID_TRADER_NAME':
            return 'Ungültiger Organisationsname';

          case 'E_TAX_NUMBER_INVALID_TRADER_ADDRESS':
            return 'Ungültige Adresse';

          default:
            return null;
        }
      }).filter((reason) => !!reason);

      return messages.length ? messages : true;

    } catch (error) {
      return true;
    }
  };
</script>

<style scoped>
  .row {
    margin: 0 -12px;
  }
</style>
