<template>
  <div>
    <v-menu close-on-content-click close-on-click>
      <template #activator="{on, attrs}">
        <v-btn icon v-bind="attrs" v-on="on" @click.prevent>
          <v-icon>far fa-ellipsis-v</v-icon>
        </v-btn>
      </template>
      <v-list>
        <slot name="prepend-items"></slot>
        <v-list-item :to="{name: 'inbox-update', params: {inboxId: inbox.id}}">
          <v-list-item-icon>
            <v-icon>fal fa-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Einstellungen</v-list-item-title>
        </v-list-item>
        <v-list-item @click="$refs.color.open()">
          <v-list-item-icon>
            <v-icon>fal fa-palette</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Farbe ändern</v-list-item-title>
        </v-list-item>

        <slot name="append-items"></slot>
      </v-list>
    </v-menu>

    <InboxColor ref="color" :inbox="inbox" />
  </div>
</template>

<script>
  import InboxColor from './InboxColor';

  export default {
    name: 'InboxActions',

    components: {
      InboxColor
    },

    props: {
      inbox: {
        type: Object,
        default: () => ({})
      }
    }
  };
</script>

<style scoped>
  .v-list >>> .v-list-item__icon {
    margin-right: 12px;
  }
</style>
