<template>
  <v-app>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <XcaSidebar
      v-if="isLoggedIn && $route.path !== '/'"
      :open="sidebarOpen"
      mini
      no-mini-toggle
      :permanent="$vuetify.breakpoint.smAndUp"
      :width="200"
      :items="sidebarItems"
      :user-stack-user="userStack"
      :logo="require('./assets/img/logo.png')"
      :logo-icon="require('./assets/img/logo-icon.png')"
      @logo-click="$route.name !== 'inboxes' ? $router.push({name: 'inboxes'}) : null"
    ></XcaSidebar>

    <v-main>
      <xca-topbar v-if="showTopbar" flat>
        <v-btn
          v-if="$vuetify.breakpoint.xs"
          icon depressed
          title="Menü einklappen"
          class="menu-button"
          :class="{'mr-2': $vuetify.breakpoint.mdAndUp}"
          @click="globalEvent('open-sidebar')"
        >
          <v-icon small>far fa-bars</v-icon>
        </v-btn>
        <v-toolbar-title>{{$route.meta.title || $route.name || $route.path.substring(1)}}</v-toolbar-title>
      </xca-topbar>

      <router-view />
    </v-main>
  </v-app>
</template>

<script>
  import App from 'xca-app-core/src/App';
  import {XcaTopbar, XcaSidebar} from 'xca-app-components';
  import {addNavItemsLabel, getRouteTitle} from './utils/navigation';

  export default {
    components: {
      XcaSidebar,
      XcaTopbar
    },

    extends: App,

    data() {
      const sidebarItems = [
        {to: '/inboxes', icon: 'fal fa-inbox'},
        // {to: '/preferences', icon: 'fal fa-cog', append: true},
        {href: '/logout', icon: 'fal fa-sign-out', label: 'Logout', append: true}
      ];
      addNavItemsLabel(sidebarItems);

      return {
        title: '',
        title_original: '',
        sidebarOpen: false,
        sidebarItems
      };
    },

    computed: {
      showTopbar() {
        const {$route, isLoggedIn} = this;
        const viewsWithoutTopbar = [
          'inboxes', 'inbox-create', 'inbox-update', 'inbox', 'documents', 'document'
        ];
        const matched = $route.matched[$route.matched.length - 1];
        return isLoggedIn && $route.path !== '/' && !viewsWithoutTopbar.includes(matched.name);
      },

      userStack() {
        const user = this.$store.getters['session/getUser'];
        return {
          // avatar: 'https://tinyfac.es/data/avatars/475605E3-69C5-4D2B-8727-61B7BB8C4699-500w.jpeg',
          name: user.userName,
          subline: user.userEmail
        };
      }
    },

    watch: {
      '$route.matched'() {
        this.$nextTick(() => this._setTitle());
      },

      title(title) {
        const {title_original} = this;
        document.title = title ? `${title} | ${title_original}` : title_original;
      }
    },

    mounted() {
      this.title_original = document.title;
      this._setTitle();

      window.addEventListener('open-sidebar', () => {
        this.sidebarOpen = false;
        this.$nextTick(() => this.sidebarOpen = true);
      });
    },

    destroyed() {
      if (this.title_original) {
        document.title = this.title_original; // Revert title - fix for hot reload.
      }
    },

    methods: {
      _setTitle() {
        if (this._titleInstance) {
          this._titleInstance.$off('documentTitle', this._titleListener);
          this._titleInstance = null;
        }

        let title = '';
        const matchedRoutes = this.$route.matched;
        const route = matchedRoutes[matchedRoutes.length - 1];
        if (route && route.name !== 'landingPage') {
          const instance = route.instances.default;
          if (instance && typeof instance.documentTitle === 'string') {
            title = instance.documentTitle;
            // Listen for `documentTitle` changes.
            instance.$on('documentTitle', this._titleListener);
            this._titleInstance = instance;
          } else {
            title = getRouteTitle(route);
          }
        }
        this.title = title;
      },

      _titleListener(title) {
        this.title = title;
      }
    }
  };
</script>

<style lang="scss">
  @import './assets/scss/app.scss';
</style>

<style scoped>
  .menu-button {
    margin-left: -12px;
  }
</style>
