<template>
  <fragment>
    <small v-if="document.expiry" class="text--accent mr-2">
      Verfügbar bis {{document.expiry | moment('L')}}
    </small>

    <v-spacer></v-spacer>

    <v-btn
      v-if="this.delete"
      icon
      depressed
      title="Dokument entfernen"
      color="error"
      @click="$emit('delete')"
    >
      <v-icon small>far fa-trash-alt</v-icon>
    </v-btn>

    <v-btn
      v-if="share"
      icon
      depressed
      title="Dokument freigeben"
      @click="$emit('share')"
    >
      <v-icon small>far fa-share</v-icon>
    </v-btn>

    <v-btn
      v-if="done && !document.done"
      :icon="$vuetify.breakpoint.xs"
      :class="{'ml-2': $vuetify.breakpoint.smAndUp}"
      :outlined="$vuetify.breakpoint.smAndUp"
      depressed
      color="secondary"
      title="Dokument auf erledigt setzen"
      @click="$emit('done')"
    >
      <v-icon v-if="$vuetify.breakpoint.xs" small>far fa-check-double</v-icon>
      <template v-if="$vuetify.breakpoint.smAndUp">Erledigt</template>
    </v-btn>
  </fragment>
</template>

<script>
  import {Fragment} from 'vue-fragment';

  export default {
    name: 'DocumentToolbarExtension',

    components: {
      Fragment
    },

    props: {
      document: {
        type: Object,
        default: () => ({})
      },
      delete: {
        type: Boolean,
        default: false
      },
      share: {
        type: Boolean,
        default: false
      },
      done: {
        type: Boolean,
        default: false
      }
    }
  };
</script>
