<template>
  <div>
    <h3 class="mt-2 mb-6">Aktivität</h3>
    <v-card class="activity v-card--no-shadow">
      <v-card-text>
        <table class="table mb-0" :class="{'table-responsive': responsiveTable}">
          <thead>
            <tr>
              <th>Aktion</th>
              <th>Beschreibung</th>
              <th>Datum</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(activity, i) in activities" :key="i">
              <!-- eslint-disable vue/no-v-html -->
              <td v-html="activity.title"></td>
              <td v-html="activity.description"></td>
              <!-- eslint-enable vue/no-v-html -->
              <td>{{activity.created | moment('L[,] LT [Uhr]')}}</td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import {ResizeSensor} from 'css-element-queries';

  export default {
    name: 'DocumentActivity',

    props: {
      document: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        responsiveTable: true
      };
    },

    computed: {
      activities() {
        const {document} = this;
        let {events} = document;

        // Sort by created date - ascending.
        events.sort((a, b) => new Date(a.created) - new Date(b.created));

        // Filter events.
        let firstDocumentGet = null;
        const firstDocumentDownloadFiles = [];

        events = events.filter((event) => {
          if (event.name === 'DocumentGet' && !firstDocumentGet) {
            firstDocumentGet = event;
            return true;
          }
          if (event.name === 'DocumentDownloadFile' && !firstDocumentDownloadFiles.includes(event.metadata.parameters.fileId)) {
            firstDocumentDownloadFiles.push(event.metadata.parameters.fileId);
            return true;
          }
          return ![
            'DocumentAddFile',
            'DocumentGet',
            'DocumentDownloadFile',
            'DocumentEventsList',
            'DocumentSend',
            'DocumentDownloadReceipt'
          ].includes(event.name);
        });

        // Resort by created date - descending.
        events.sort((a, b) => new Date(b.created) - new Date(a.created));

        // Create activies.
        return events.map((event) => {
          const activity = {
            created: event.created,
            user: event.actor.userName,
            title: event.name,
            description: '-'
          };
          const {parameters, body} = event.metadata;

          switch (event.name) {
            case 'DocumentSign':
              const types = {
                SIGNATURE: 'Unterschrift',
                HANDY_SIGNATUR: 'Handy Signatur'
              };
              const receiptHref = `/api/inboxes/${parameters.inboxId}/documents/${parameters.documentId}/receipt`;
              activity.title = 'Erhalt bestätigt';
              activity.description = `
                mittels <i>${types[document.receipt_required]}</i><br>
                <small>von <a href="${receiptHref}" target="_blank"><i>${body.name}</i></a></small>
              `;
              break;

            case 'DocumentDownloadFile':
              activity.title = 'Datei abgerufen';
              const file = document.files.find((f) => f.id === parameters.fileId);
              const fileHref = `/api/inboxes/${parameters.inboxId}/documents/${parameters.documentId}/files/${file.id}`;
              activity.description = `<a href="${fileHref}" target="_blank"><i>${file.name}</i></a>`;
              break;

            case 'DocumentGet':
              activity.title = 'Dokument geöffnet';
              break;

            case 'DocumentShare':
              activity.title = 'Dokument freigegeben';
              activity.description = `für <i>${body.emails.join(', ')}</i>`;
              break;

            case 'DocumentShareDelete':
              activity.title = 'Freigabe entfernt';
              activity.description = `für <i>${body.emails.join(', ')}</i>`;
              break;

            case 'DocumentSetDone':
              activity.title = 'Dokument erledigt';
              break;

            case 'DocumentDelete':
              activity.title = 'Dokument entfernt';
              break;
          }

          return activity;
        });
      }
    },

    mounted() {
      const element = this.$el;
      const table = element.querySelector('.table thead');

      new ResizeSensor(element, () => {
        this.responsiveTable = true;
        requestAnimationFrame(() => {
          this.responsiveTable = element.clientWidth - 32 <= table.clientWidth;
        });
      });
    }
  };
</script>

<style lang="scss" scoped>
  .activity {
    .v-card__text {
      @import '~bootstrap/scss/_functions';
      @import '~bootstrap/scss/_variables';
      @import '~bootstrap/scss/_mixins';
      @import '~bootstrap/scss/_tables';

      table {
        border-collapse: collapse;
        text-align: left;
      }

      .table th,
      .table td {
        border-top: none;
        vertical-align: middle;
      }
    }
  }
</style>
