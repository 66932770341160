export default function watchDocumentVisibility(onChange) {
  // https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API#Example
  let hidden, visibilityChange;
  if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }

  let visibilityChanged;
  if (hidden !== undefined) {
    visibilityChanged = () => onChange(!document[hidden]);
    document.addEventListener(visibilityChange, visibilityChanged, false);
  }

  const onBlur = () => onChange(false);
  window.addEventListener('blur', onBlur);

  const onFocus = () => onChange(true);
  window.addEventListener('focus', onFocus);

  // Return `unwatch` function.
  return function unwatch() {
    if (visibilityChanged) {
      document.removeEventListener(visibilityChange, visibilityChanged);
    }
    window.removeEventListener('blur', onBlur);
    window.removeEventListener('focus', onFocus);
  };
}
