import {types as notificationTypes} from '../components/notifications/Notifications';

export const incomplete = (inbox) => {
  // Complete if there is at least one verified address.
  if (inbox && inbox.addresses && inbox.addresses.find((addr) => addr.verified)) {
    return false;
  }
  return true;
};


export const unverified_addresses = (inbox) => {
  if (inbox && inbox.addresses && inbox.addresses.length) {
    const unverified = inbox.addresses.filter((address) => !address.verified);
    return unverified.length;
  }
  return 0;
};


export const unverified_emails = (inbox) => {
  if (inbox && inbox.emails && inbox.emails.length) {
    const unverified = inbox.emails.filter((email) => !email.verified);
    return unverified.length;
  }
  return 0;
};


export const unverified_phones = (inbox) => {
  if (inbox && inbox.phones && inbox.phones.length) {
    const unverified = inbox.phones.filter((phone) => !phone.verified);
    return unverified.length;
  }
  return 0;
};


export const unverified_notifications = (inbox) => {
  if (inbox && inbox.notifications && inbox.notifications.length) {
    const unverified = inbox.notifications.filter((notification) => {
      if (!notification.enabled) {
        return false;
      }
      const type = notificationTypes.find((nT) => nT.name === notification.type).contactType;
      return !notification[type].verified;
    });
    return unverified.length;
  }
  return 0;
};


export const unverified_sender_data = (inbox) => {
  if (inbox && inbox.sender_data) {
    const sender_data = Object.values(inbox.sender_data).filter((data) => typeof data === 'object');
    const unverified = sender_data.filter((data) => !data.verified);
    return unverified.length;
  }
  return 0;
};


export const unverified = (inbox) => {
  if (inbox) {
    const unverifiedContacts = {
      addresses: unverified_addresses(inbox),
      emails: unverified_emails(inbox),
      notifications: unverified_notifications(inbox),
      phones: unverified_phones(inbox),
      sender_data: unverified_sender_data(inbox)
    };
    unverifiedContacts.length = Object.values(unverifiedContacts).reduce((a, b) => a + b, 0);
    if (unverifiedContacts.length) {
      return unverifiedContacts;
    }
  }
  return false;
};
