import locales from 'xca-app-core/src/locales';
import de from './de';
import en from './en';


export default {
  de: {
    ...locales.de,
    ...de
  },
  en: {
    ...locales.en,
    ...en
  }
};
