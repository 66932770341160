<template>
  <v-dialog v-model="opened" :persistent="loading" :max-width="maxWidth" scrollable>
    <v-card>
      <v-card-title class="headline">Dokument "{{document.name}}" entfernen?</v-card-title>
      <v-alert v-if="error" type="error" text>
        Dokument konnte nicht entfernt werden.<br>
        Bitte versuchen Sie es später noch einmal.
      </v-alert>
      <v-card-text class="pb-0">
        Dokument kann nach dem Entfernen nicht wiederhergestellt werden.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed :disabled="loading" @click="close">
          Abbrechen
        </v-btn>
        <v-btn color="error" depressed :loading="loading" @click="this.delete">
          Entfernen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import DialogMixin from '../../mixins/dialog-mixin.js';

  export default {
    name: 'DocumentDelete',

    mixins: [
      DialogMixin
    ],

    props: {
      document: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        opened: false, // DialogMixin
        loading: false,
        error: false
      };
    },

    watch: {
      opened(opened) {
        if (!opened) {
          this.error = false;
        }
      }
    },

    methods: {
      async delete() {
        this.error = false;
        this.loading = true;

        try {
          await this.$apiOperation('DocumentDelete', {parameters: {
            documentId: this.document.id,
            inboxId: this.document.inboxId
          }});

          this.loading = false;
          this.close(); // DialogMixin
          this.$router.push({
            name: 'inbox',
            params: {inboxId: this.document.inboxId}
          });

        } catch (error) {
          this.loading = false;
          this.error = true;
        }
      }
    }
  };
</script>
