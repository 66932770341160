<template>
  <v-dialog v-model="opened" :persistent="loading" :max-width="maxWidth" scrollable>
    <v-card>
      <v-card-title class="headline">Farbe für "{{inbox.name}}" ändern</v-card-title>
      <v-alert v-if="error" type="error" text>
        Postfach konnte nicht aktualisiert werden.<br>
        Bitte versuchen Sie es später noch einmal.
      </v-alert>
      <v-card-text class="pb-0">
        <ColorSelector v-model="color" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed :disabled="loading" @click="close">
          Abbrechen
        </v-btn>
        <v-btn color="success" depressed :loading="loading" @click="update">
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import DialogMixin from '../../mixins/dialog-mixin.js';
  import ColorSelector from '../ColorSelector';
  import {inboxUpdateBody} from '../../views/InboxUpdate';

  export default {
    name: 'InboxColor',

    components: {
      ColorSelector
    },

    mixins: [
      DialogMixin
    ],

    props: {
      inbox: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        opened: false, // DialogMixin
        maxWidth: 368,
        loading: false,
        error: false,
        color: undefined
      };
    },

    watch: {
      opened(opened) {
        if (opened && this.inbox) {
          this.color = this.inbox.color;
        }
      }
    },

    methods: {
      async update() {
        if (this.loading) {
          return;
        }

        this.error = false;
        this.loading = true;

        try {
          const body = inboxUpdateBody(JSON.parse(JSON.stringify(this.inbox)));

          await this.$apiOperation('InboxUpdate', {
            parameters: {inboxId: this.inbox.id},
            requestBody: {...body, color: this.color}
          });

          // Refresh route parent element (Inboxes, Inbox)
          this.$route.matched.forEach((matched) => {
            const component = matched.instances.default;
            const componentName = component.$options.name;
            if (componentName === 'Inboxes') {
              component.refresh();
            } else if (['Inbox', 'InboxUpdate'].includes(componentName) &&
              component.inboxId === this.inbox.id) {
              component.refresh();
            }
          });

          this.loading = false;
          this.close(); // DialogMixin

        } catch (error) {
          this.loading = false;
          this.error = true;
        }
      }
    }
  };
</script>
