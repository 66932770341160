import getClassNameForExtension from 'font-awesome-filetypes';
import {format as formatNumber} from './number';


export const icon = (name = '') => {
  const extension = name.substring(name.lastIndexOf('.') + 1);
  const iconName = getClassNameForExtension(extension);
  return 'fal ' + iconName || 'fa-question';
};


// https://stackoverflow.com/a/18650828
export const size = (bytes) => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const sizeIndex = Math.floor(Math.log(bytes) / Math.log(k));
  const unit = units[sizeIndex];
  const sizeFormatted = formatNumber(bytes / Math.pow(k, sizeIndex), {
    minimumFractionDigits: 1,
    maximumFractionDigits: 2
  });

  return `${sizeFormatted} ${unit}`;
};
