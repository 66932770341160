<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <v-alert type="info" text>
      Absenderinformationen werden von manchen Produkten benötigt
      <small>(z.B. <strong>XIDRAS</strong><i>Postmaster</i>)</small>.<br>
      Falls diese benötigt werden, werden Sie vom jeweiligen Produkt
      darauf hingewiesen.<br>
      Folgende Daten werden z.B. als Kontaktdaten für den Empfänger
      Ihrer Nachrichten angezeigt.
    </v-alert>

    <Contacts
      v-model="inbox.sender_data.email"
      :inbox="inbox"
      type="email"
    />

    <Contacts
      v-model="inbox.sender_data.phone"
      :inbox="inbox"
      type="phone"
    />

    <v-select
      v-model="inbox.sender_data.address"
      :items="[...inbox.addresses]"
      :item-text="_contactItemText"
      :hint="addressHint"
      :class="{
        'verification-pending': inbox.sender_data.address && ['PENDING', undefined].includes(inbox.sender_data.address.status),
        'verification-failed': inbox.sender_data.address && inbox.sender_data.address.status === 'FAILED'
      }"
      label="Absenderadresse auswählen"
      prepend-inner-icon="fal fa-home"
      no-data-text="Keine Adressen zugewiesen"
      menu-props="closeOnContentClick"
      persistent-hint
      outlined
      dense
      clearable
    >
      <template #prepend-item>
        <v-list-item>
          <v-list-item-content>
            <div class="d-flex align-center">
              <span class="flex">Wählen Sie eine Adresse aus Ihrer Adressenzuweisung.</span>
              <v-btn class="ml-2" outlined depressed small @click="$router.replace({query: {...$route.query, step: 2}})">
                Zur Adressenzuweisung
              </v-btn>
            </div>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template #item="data">
        <v-list-item @click="_setSenderAddress(data)">
          <v-list-item-icon>
            <v-icon>fal fa-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <div class="d-flex align-center justify-end">
              <div class="flex text-truncate">
                <ContactTitle type="address" :contact="data.item" />
                <ContactSubtitle type="address" :contact="data.item" />
              </div>
              <ContactStatus :contact="data.item" />
            </div>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>

    <v-text-field
      v-model="inbox.sender_data.contactPerson"
      type="text"
      name="contactPerson"
      label="Kontaktperson (optional)"
      prepend-inner-icon="far fa-user"
      outlined
      dense
    ></v-text-field>

    <v-text-field
      v-model="inbox.sender_data.website"
      :rules="[rules.url]"
      type="text"
      name="website"
      label="Webseite (optional)"
      placeholder="z.B. https://example.com"
      prepend-inner-icon="far fa-globe-europe"
      outlined
      dense
    ></v-text-field>
  </div>
</template>

<script>
  import Contacts from '../contacts/Contacts';
  import ContactTitle from '../contacts/ContactTitle';
  import {title as contactTitle} from '../contacts/ContactTitle';
  import {subtitle as contactSubtitle} from '../contacts/ContactSubtitle';
  import ContactSubtitle from '../contacts/ContactSubtitle';
  import ContactStatus from '../contacts/ContactStatus';
  import {texts as verificationTexts} from '../contacts/ContactStatus';
  import * as rules from '../../utils/rules';

  export default {
    name: 'InboxSenderFields',

    components: {
      Contacts,
      ContactTitle,
      ContactSubtitle,
      ContactStatus
    },

    props: {
      inbox: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        rules
      };
    },

    computed: {
      addressHint() {
        const {sender_data} = this.inbox;
        if (sender_data && sender_data.address && sender_data.address.status !== 'SUCCESS') {
          return verificationTexts[sender_data.address.status];
        }
        return undefined;
      }
    },

    methods: {
      _contactItemText(contact) {
        return `${contactTitle(contact, 'address')} • ${contactSubtitle(contact, 'address')}`;
      },

      _setSenderAddress(data) {
        this.$set(this.inbox.sender_data, 'address', data.item);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .v-select.verification-pending::v-deep {
    fieldset {
      border-color: $warning-light;
    }

    .v-messages__message {
      color: $warning-light;
    }
  }

  .v-select.verification-failed::v-deep {
    fieldset {
      border-color: $error-light;
    }

    .v-messages__message {
      color: $error-light;
    }
  }
</style>
