<template>
  <v-form ref="form" :readonly="loading" lazy-validation @submit.prevent="submit">
    <v-alert v-if="error" type="error" text>
      Dokument konnte nicht unterschrieben werden.<br>
      Bitte versuchen Sie es später noch einmal.
    </v-alert>

    <strong class="d-block mb-2">Bitte unterschreiben Sie hier:</strong>
    <v-text-field
      v-model="data.signature"
      class="signature-field"
      :rules="[rules.required]"
      type="text"
      name="signature"
      outlined
    >
      <template #prepend-inner>
      </template>
      <template #append>
        <div class="signature-pad d-flex align-end pt-5 px-5 pb-6">
          <div class="d-flex align-center w-100">
            <v-icon class="signature-times text--error">far fa-times</v-icon>
            <span class="text--accent ml-5">Hier unterschreiben</span>
          </div>
        </div>
        <VueSignaturePad
          ref="signaturePad"
          :options="{penColor: '#39689f', onEnd: () => signatureEmpty = false}"
          class="signature-pad"
        />
        <div v-if="!signatureEmpty" class="signature-actions">
          <v-btn icon @click="undoSignature"><v-icon>far fa-undo</v-icon></v-btn>
          <v-btn icon @click="resetSignature"><v-icon>far fa-times</v-icon></v-btn>
        </div>
      </template>
    </v-text-field>

    <strong class="d-block mb-2">Geben Sie Ihren Namen ein:</strong>
    <v-text-field
      v-model="data.name"
      class="input-name"
      :rules="[rules.required]"
      type="text"
      name="name"
      placeholder="Name eingeben"
      outlined
      dense
    ></v-text-field>

    <v-btn color="success" depressed :loading="loading" @click="submit">
      Erhalt jetzt bestätigen
    </v-btn>
  </v-form>
</template>

<script>
  export default {
    name: 'DocumentSignature',

    props: {
      document: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        error: false,
        loading: false,
        rules: {
          required: (value) => {
            return !!value || 'Feld erforderlich';
          }
        },
        data: {
          name: '',
          signature: ''
        },
        signatureEmpty: true
      };
    },

    methods: {
      async submit() {
        if (this.loading) {
          return;
        }

        this.error = false;

        const {signaturePad} = this.$refs;
        this.$set(this.data, 'signature', signaturePad.isEmpty() ? '' : 'sig');
        await new Promise((resolve) => this.$nextTick(() => resolve()));

        const valid = this.$refs.form.validate();
        if (!valid) {
          return;
        }
        this.loading = true;

        try {
          let signature = signaturePad.signaturePad.toDataURL('image/svg+xml');
          const base64Prefix = 'data:image/svg+xml;base64,';
          if (signature.indexOf(base64Prefix) === 0) {
            signature = signature.substring(base64Prefix.length);
          }

          await this.$apiOperation('DocumentSign', {
            parameters: {
              documentId: this.document.id,
              inboxId: this.document.inboxId
            },
            requestBody: {...this.data, signature}
          });

          this.$emit('submit');
          this.loading = false;
          this.reset();

        } catch (error) {
          this.loading = false;
          this.error = true;
        }
      },

      reset() {
        this.$refs.form.reset();
        this.resetSignature();
      },

      resetSignature() {
        this.$refs.signaturePad.clearSignature();
        this.signatureEmpty = true;
      },

      undoSignature() {
        this.$refs.signaturePad.undoSignature();
        this.signatureEmpty = this.$refs.signaturePad.isEmpty();
      }
    }
  };
</script>

<style scoped>
  .v-text-field--outlined >>> .v-input__control > .v-input__slot {
    background: #fff;
  }

  .signature-field >>> .v-text-field__slot {
    height: 200px;
  }

  .signature-field >>> input {
    display: none;
  }
</style>

<style lang="scss" scoped>
  @import "../../assets/scss/variables";

  .signature-pad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 26px;
    cursor: cell;
  }

  .signature-pad:first-child::after {
    display: block;
    content: "";
    position: absolute;
    bottom: 23px;
    left: 20px;
    width: calc(100% - 40px);
    height: 1px;
    background: $accent-light;
  }

  .signature-times {
    font-size: 60px;
  }

  .signature-actions {
    position: absolute;
    top: 20px;
    right: 20px;

    .v-icon.fa-undo {
      width: 20px;
      font-size: 20px;
    }
  }

  .input-name {
    max-width: 300px;
  }
</style>
