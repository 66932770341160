<template>
  <div>
    <v-alert type="info" text>
      Gruppen werden benötigt um mehreren Personen Zugriff auf dieses Postfach zu geben.<br>
      Personen und Gruppen werden in Ihrem
      <a :href="groupsHref" target="_blank">Xidras.io Konto <v-icon small class="text--primary">far fa-external-link</v-icon></a> verwaltet.<br>
      Fügen Sie Personen einer Gruppe hinzu und weisen Sie diese Gruppe dann hier dem Postfach zu.<br>
      Beispiele für Gruppen können sein: Familie, Abteilung oder Projekt.
    </v-alert>

    <v-list subheader>
      <v-list-item v-if="!value || !value.length" class="px-0">
        <v-list-item-icon>
          <v-icon color="accent">fal fa-user-lock</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="text--accent">
          <v-list-item-title>
            Noch keine Gruppe zugewiesen.
          </v-list-item-title>
          <v-list-item-subtitle>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <div v-if="value.length" class="permissions d-flex justify-end">
        <span class="permission">lesen</span>
        <span class="permission">schreiben</span>
      </div>

      <v-list-item v-for="(group, index) in value" :key="index" class="px-0">
        <v-list-item-icon>
          <v-icon class="mr-2">fal fa-user-lock</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <div class="d-flex align-center justify-end">
            <div class="flex">
              <v-list-item-title>{{group.name}}</v-list-item-title>
              <v-list-item-subtitle v-if="group.description">
                {{group.description}}
              </v-list-item-subtitle>
            </div>
            <div class="d-flex self-end justify-center ml-4">
              <span class="permission">
                <v-checkbox v-model="group.permissions" color="primary" value="read" dense></v-checkbox>
              </span>
              <span class="permission">
                <v-checkbox v-model="group.permissions" color="primary" value="write" dense></v-checkbox>
              </span>
            </div>
          </div>
        </v-list-item-content>

        <v-list-item-avatar>
          <v-btn
            icon
            title="Gruppe entfernen"
            @click="_splice(group)"
          >
            <v-icon>fal fa-trash-alt</v-icon>
          </v-btn>
        </v-list-item-avatar>
      </v-list-item>
    </v-list>

    <h5 class="mb-2">Neue Gruppe hinzufügen</h5>

    <v-select
      :items="groups_filtered"
      label="Gruppe auswählen"
      :no-data-text="`Keine ${groups.length ? 'weiteren ' : ''} Gruppen vorhanden.`"
      prepend-inner-icon="fal fa-users"
      outlined
      dense
    >
      <template #item="data">
        <v-list-item @click="_push(data.item)">
          <v-list-item-icon>
            <v-icon>fal fa-users</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{data.item.name}}
            </v-list-item-title>
            <v-list-item-subtitle v-if="data.item.description">
              {{data.item.description}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template #append-item>
        <v-divider class="mb-2"></v-divider>
        <v-list-item ripple :href="groupsHref" target="_blank">
          <v-list-item-action>
            <v-icon>fal fa-users-cog</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Gruppen bearbeiten - <i>Xidras.io Konto</i><v-icon small class="ml-2">far fa-external-link</v-icon>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<script>
  export default {
    name: 'Groups',

    props: {
      value: {
        type: Array,
        default: () => []
      }
    },

    data() {
      return {
        request: null,
        error: false,
        groups: [],
        groupsHref: 'https://accounts.xidras.io/groups'
      };
    },

    computed: {
      groups_filtered() {
        const {value, groups} = this;
        const groupIds = value.map((g) => g.id);
        return groups.filter((g) => !groupIds.includes(g.id));
      },

      loading() {
        return !!this.request;
      }
    },

    async mounted() {
      await this.refresh();
    },

    methods: {
      async refresh() {
        if (this.request) {
          await this.request;
          return this.groups;
        }

        try {
          this.error = false;
          this.request = this.$apiOperation('GroupsList');

          const {data} = await this.request;
          this.groups = data.results || [];

        } catch (error) {
          this.error = true;
          this.groups = [];
        }

        this.request = null;
        return this.groups;
      },

      _push(group) {
        group = {...group, permissions: []};
        delete group.etag;
        delete group.isDepartment;
        delete group.roles;
        delete group.memberCount;

        const value = [...this.value, group];
        this.$emit('input', value);
      },

      _splice(group) {
        const value = [...this.value];
        const index = value.findIndex((g) => g.id === group.id);
        value.splice(index, 1);
        this.$emit('input', value);
      }
    }
  };
</script>

<style scoped>
  .permissions {
    font-size: 12px;
    margin-right: 56px;
  }

  .permission {
    min-width: 60px;
    text-align: center;
  }
</style>
