import routerRoutes from '../routes';


/**
 * Get route data by name or path
 * @param {string} key route name or path
 * @param {string} type name or path
 * @param {Array} routes
 * @return {Object} route
 */
export const getRoute = (key, type = 'name', routes = routerRoutes) => {
  for (let i = 0; i < routes.length; ++i) {
    const route = routes[i];

    if (route[type] === key) {
      return route;
    }

    if (route.children) {
      const childRoute = getRoute(key, type, route.children);
      if (childRoute) {
        return childRoute;
      }
    }
  }
  return null;
};


/**
 * Get route title
 * @param {Object} route
 * @return {string} route title
 */
export const getRouteTitle = (route) => {
  if (route.meta && route.meta.title) {
    return route.meta.title;
  }
  if (route.name) {
    return route.name;
  }
  if (route.path.startsWith('/')) {
    return route.path.substring(1);
  }
  return route.path;
};


/**
 * Add label to navItems
 * @param {Object} navItems XcaDrawer/XcaToolbar items
 * @param {string} opt_prefix optional path prefix
 */
export const addNavItemsLabel = (navItems, opt_prefix = '') => {
  for (let i = 0; i < navItems.length; ++i) {
    const navItem = navItems[i];
    if (navItem.label || !navItem.to) {
      continue;
    }

    const path = opt_prefix + navItem.to;
    const route = getRoute(path, 'path');

    navItem.label = getRouteTitle(route || {path});
  }
};
