import AddressFields from './AddressFields';
import EmailFields from './EmailFields';
import PhoneFields from './PhoneFields';


export default {
  AddressFields,
  EmailFields,
  PhoneFields
};
