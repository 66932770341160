<template>
  <div class="d-flex flex-column justify-center align-center">
    <div v-if="!document" class="text--accent text-center w-100">
      <div v-if="loading">
        <v-progress-circular indeterminate color="accent" class="mb-2"></v-progress-circular>
        <p>Dokument wird geladen ...</p>
      </div>
      <div v-if="!loading && error" class="text--error">
        <div v-if="error === 404">
          <p>
            Dokument nicht gefunden.<br>
            <small>
              Entweder funktioniert der von Ihnen angeklickte Link nicht oder das Dokument wurde entfernt.
            </small>
          </p>
          <v-btn id="back" depressed color="primary" small :to="{name: 'documents'}">
            Zu den Dokumenten
          </v-btn>
        </div>
        <div v-else>
          <p>Dokument konnte nicht geladen werden.</p>
          <div v-if="error === 500 || error === true">
            <v-btn depressed color="primary" small @click="refresh">Erneut versuchen</v-btn>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="w-100">
      <DocumentViewReceived
        v-if="document.status === 'RECEIVED'"
        :document="document"
        :drawer-open="drawerOpen"
      />
      <DocumentViewSent
        v-else-if="['SENT', 'DRAFT'].includes(document.status)"
        :document="document"
        :drawer-open="drawerOpen"
      />
      <DocumentViewContainer
        v-else
        :document="document"
        :drawer-open="drawerOpen"
      >
        <p class="text--error">Keine Anzeige für Status "{{document.status}}" verfügbar.</p>
      </DocumentViewContainer>
    </div>
  </div>
</template>

<script>
  import DetailMixin from '../mixins/detail-mixin';
  import DocumentViewReceived from '../components/document/DocumentViewReceived';
  import DocumentViewSent from '../components/document/DocumentViewSent';
  import DocumentViewContainer from '../components/document/DocumentViewContainer';


  const detailMixin = DetailMixin({
    item: 'document',
    id: 'documentId',
    operationId: 'DocumentGet',
    parameters() {
      return {inboxId: this.$route.params.inboxId};
    }
  });

  export default {
    name: 'Document',

    components: {
      DocumentViewReceived,
      DocumentViewSent,
      DocumentViewContainer
    },

    mixins: [
      detailMixin
    ],

    props: {
      drawerOpen: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      // Extend DetailMixin's refresh method.
      async refresh() {
        const document = await detailMixin.methods.refresh.apply(this);
        try {
          if (this.error) {
            return; // Error handled in detailMixn.
          }

          this.request = this.$apiOperation('DocumentEventsList', {
            parameters: {
              documentId: document.id,
              inboxId: this.$route.params.inboxId,
              limit: 10,
              offset: 0
            }
          });

          const {data: {results: events}} = await this.request;
          this.$set(document, 'events', events);

          this.request = null;

        } catch (error) {
          this.request = null;
          const status = error.response ? error.response.status : null;
          this.error = status || true;
        }
      }
    }
  };
</script>

<style scoped>
  #back.v-btn--active::before {
    background-color: transparent;
  }
</style>
