<template>
  <div :class="{
    'has-children': menu || drawer,
    'mr-2': $vuetify.breakpoint.mdAndUp
  }">
    <v-btn
      v-if="menu"
      icon depressed
      title="Menü einklappen"
      @click="globalEvent('open-sidebar')"
    >
      <v-icon small>far fa-bars</v-icon>
    </v-btn>

    <v-btn
      v-if="drawer"
      :title="drawerOpen ? `${drawerName} einklappen` : `${drawerName} ausklappen`"
      icon
      depressed
      @click="globalEvent('toggle-drawer')"
    >
      <v-icon small>
        {{$vuetify.breakpoint.mdAndUp ? 'far fa-bars' : 'far fa-chevron-left'}}
      </v-icon>
    </v-btn>

    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'TopbarNavigation',

    props: {
      drawer: {
        type: Boolean,
        default: false
      },
      drawerOpen: {
        type: Boolean,
        default: false
      },
      drawerName: {
        type: String,
        default: 'Dokumente'
      }
    },

    computed: {
      menu() {
        return !this.drawer && this.$vuetify.breakpoint.xs;
      }
    }
  };
</script>

<style scoped>
  .has-children {
    margin-left: -12px;
  }
</style>
