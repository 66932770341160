const options_default = {
  items: 'items',
  id: 'itemId',
  operationId: 'ItemsList',
  parameters: {},
  limit: 50
};

export default (options = {}) => {
  options = {...options_default, ...options};

  return {
    props: {
      page: { // router
        type: Number,
        default: 1
      }
    },

    data() {
      return {
        request: null,
        error: false,
        [options.items]: [],
        metadata: null
      };
    },

    computed: {
      [options.id]() {
        return this.$route.params[options.id];
      },

      loading() {
        return !!this.request;
      },

      pages_count() {
        const {metadata} = this;
        if (!metadata || !metadata.total_count) {
          return 1;
        }
        return Math.ceil(metadata.total_count / options.limit);
      }
    },

    watch: {
      '$route.matched'(matched) {
        if (matched.length === 1 && matched[0].instances.default === this) {
          this.refresh();
        }
      },

      async page() {
        try {
          this[options.items] = [];
          await this.refresh();
        } catch (error) {
          /* NOOP */
        }
      }
    },

    async mounted() {
      await this.refresh();
    },

    methods: {
      async refresh() {
        if (this.request) {
          await this.request;
          return this[options.items];
        }

        try {
          this.error = false;

          let {parameters, limit} = options;
          parameters = typeof parameters === 'function' ? parameters.apply(this) : parameters;

          let page = parseInt(this.page);
          if (page < 1) {
            page = 1;
          }
          if (page > this.pages_count) {
            page = this.pages_count;
          }

          parameters = {
            ...parameters,
            limit,
            offset: (page - 1) * limit
          };

          this.request = this.$apiOperation(options.operationId, {parameters});

          const {data} = await this.request;
          this[options.items] = data.results || [];
          this.metadata = data.metadata;

        } catch (error) {
          this.error = true;
          this[options.items] = [];
          this.metadata = null;
        }

        this.request = null;
        return this[options.items];
      }
    }
  };
};
