<template>
  <div>
    <template v-if="multiple">
      <v-list subheader>
        <v-list-item v-if="!values || !values.length" class="px-0">
          <v-list-item-icon>
            <v-icon color="accent">{{icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="text--accent">
            <v-list-item-title>
              Noch keine {{$t(`contacts.${type}.single`)}} zugewiesen.
            </v-list-item-title>
            <v-list-item-subtitle>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-for="(val, index) in values" :key="index" class="px-0">
          <v-list-item-icon>
            <v-icon class="mr-2">{{icon}}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <div class="d-flex align-center justify-end">
              <div class="flex text-truncate">
                <slot name="list-item" :contact="val">
                  <ContactTitle :type="type" :contact="val" />
                  <ContactSubtitle :type="type" :contact="val" />
                </slot>
              </div>
              <ContactStatus v-if="valueContact(val).status !== 'SUCCESS'" :contact="val" />
              <slot name="list-item-append" :contact="val"></slot>
            </div>
          </v-list-item-content>

          <v-list-item-avatar>
            <v-btn
              icon
              :title="$t(`contacts.${type}.single`) + ' entfernen'"
              @click="_remove(index)"
            >
              <v-icon>fas fa-times-circle</v-icon>
            </v-btn>
          </v-list-item-avatar>
        </v-list-item>
      </v-list>

      <h5 class="mb-2">{{$t(`contacts.${type}.single`)}} hinzufügen</h5>
    </template>

    <div @click="unverifiedContacts && refresh()">
      <v-select
        :value="value"
        :items="contacts_filtered"
        :label="$t(`contacts.${type}.single`) + ' auswählen'"
        :item-text="_itemText"
        :no-data-text="`Keine ${contacts.length ? 'weiteren ' : ' '}${$t(`contacts.${type}.multiple`)} vorhanden.`"
        :prepend-inner-icon="icon"
        :clearable="!multiple"
        :persistent-hint="!multiple && value && !valueContact(value).verified"
        :hint="hint"
        :class="{
          'verification-pending': !multiple && value && ['PENDING', undefined].includes(valueContact(value).status),
          'verification-failed': !multiple && value && valueContact(value).status === 'FAILED'
        }"
        menu-props="closeOnContentClick"
        outlined
        dense
        @click:clear="$emit('input', undefined)"
      >
        <template #item="data">
          <v-list-item :disabled="!_validContact(data.item)" @click="_add(data.item)">
            <v-list-item-icon>
              <v-icon>{{icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="d-flex align-center justify-space-between">
                <div>
                  <ContactTitle :type="type" :contact="data.item" />
                  <ContactSubtitle :type="type" :contact="data.item" />
                </div>
                <ContactStatus :contact="data.item" />
              </div>
            </v-list-item-content>

            <v-list-item-avatar @click.stop>
              <v-btn
                icon
                :title="$t(`contacts.${type}.single`) + ' entfernen'"
                @click="$refs.delete.open(data.item)"
              >
                <v-icon>fal fa-trash-alt</v-icon>
              </v-btn>
            </v-list-item-avatar>
          </v-list-item>
        </template>

        <template #append-item>
          <v-divider class="mb-2"></v-divider>
          <v-list-item ripple @click="$refs.create.open()">
            <v-list-item-action>
              <v-icon>fal fa-plus</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Neue {{$t(`contacts.${type}.single`)}} anlegen
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-select>
    </div>

    <ContactCreate ref="create" :type="type" :inbox="inbox" @create="_onCreate" />
    <ContactDelete ref="delete" :type="type" :inbox="inbox" @delete="_onDelete" />
  </div>
</template>

<script>
  import ContactTitle, {title} from './ContactTitle';
  import ContactSubtitle, {subtitle} from './ContactSubtitle';
  import ContactStatus from './ContactStatus';
  import {texts as verificationTexts} from './ContactStatus';
  import ContactCreate from './ContactCreate';
  import ContactDelete from './ContactDelete';
  import {contactTypesMap} from '../../store-modules/inbox-contacts';

  export default {
    name: 'Contacts',

    components: {
      ContactTitle,
      ContactSubtitle,
      ContactStatus,
      ContactCreate,
      ContactDelete
    },

    props: {
      type: {
        type: String,
        default: 'email'
      },
      value: {
        type: [Object, Array],
        default: undefined
      },
      inbox: {
        type: Object,
        default: () => ({})
      },
      multiple: {
        type: Boolean,
        value: false
      },
      valueContact: {
        type: Function,
        default: (value) => value
      }
    },

    computed: {
      contacts() {
        const contacts = contactTypesMap[this.type];
        return this.$store.getters[`inboxContacts/${contacts}`](this.inbox.id);
      },

      values() {
        const {value} = this;
        if (!value) {
          return [];
        }
        if (!Array.isArray(value)) {
          return [value];
        }
        return value;
      },

      contacts_filtered() {
        let contacts = [...this.contacts];

        const {value, multiple, valueContact} = this;
        if (multiple && Array.isArray(value)) {
          const contactIds = value.map((v) => valueContact(v).id);
          contacts = contacts.filter((c) => !contactIds.includes(c.id));
        }

        return contacts;
      },

      icon() {
        switch (this.type) {
          case 'email':
            return 'fal fa-at';

          case 'phone':
            return 'fal fa-mobile';

          case 'address':
            return 'fal fa-home';

          default:
            return 'fal fa-question';
        }
      },

      headline() {
        const key = `contacts.${this.type}.multiple`;
        const translated = this.$t(key);
        if (translated !== key) {
          return translated;
        }
        return `Unkbekannter Typ "${this.type}"`;
      },

      hint() {
        if (!this.multiple && this.value) {
          return verificationTexts[this.valueContact(this.value).status];
        }
        return undefined;
      },

      loading() {
        return !!this.request;
      },

      unverifiedContacts() {
        return !!this.contacts.filter((contact) => {
          return contact.verified === false && contact.status === 'PENDING';
        }).length;
      }
    },

    watch: {
      async inbox() {
        await this.refresh();
      }
    },

    async mounted() {
      // Load contacts if there was no request yet.
      const {type, inbox: {id: inboxId}} = this;
      const request = this.$store.getters['inboxContacts/request']({type, inboxId});
      if (request === undefined) {
        await this.refresh();
      }
    },

    methods: {
      async refresh() {
        await this.$store.dispatch('inboxContacts/load', {
          type: this.type,
          inboxId: this.inbox.id
        });
      },

      _itemText(contact) {
        if (this.multiple) {
          return undefined;
        }
        return `${title(contact, this.type)} • ${subtitle(contact, this.type)}`;
      },

      _validContact(contact) {
        return contact.verified || contact.status === 'SUCCESS' || contact.status === 'PENDING';
      },

      _add(contact) {
        if (!this._validContact(contact)) {
          return;
        }
        if (this.multiple) {
          const value = [...this.value, {...contact}];
          this.$emit('input', value);
        } else {
          this.$emit('input', {...contact});
        }
      },

      _remove(index) {
        if (this.multiple) {
          const value = JSON.parse(JSON.stringify(this.value));
          value.splice(index, 1);
          this.$emit('input', value);
        } else {
          this.$emit('input', undefined);
        }
      },

      async _onCreate(id) {
        try {
          const contacts = await this.refresh();
          const contact = contacts.find((c) => c.id === id);
          if (contact) {
            this._add(contact);
          }

        } catch (error) {
          /* NOOP */
        }
      },

      async _onDelete(id) {
        try {
          await this.refresh();
          const index = this.value.findIndex((c) => c.id === id);
          if (index !== -1) {
            this._remove(index);
          }

        } catch (error) {
          /* NOOP */
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .v-chip {
    height: auto;
    min-height: 24px;
    line-height: 1.2;
    white-space: normal;
    text-align: center;
  }

  .v-list .v-list-item__avatar .v-icon.fa-times-circle {
    font-size: 24px !important;
  }

  .v-select.verification-pending::v-deep {
    fieldset {
      border-color: $warning-light;
    }

    .v-messages__message {
      color: $warning-light;
    }
  }

  .v-select.verification-failed::v-deep {
    fieldset {
      border-color: $error-light;
    }

    .v-messages__message {
      color: $error-light;
    }
  }
</style>
