<template>
  <v-list-item-subtitle :title="subtitle">{{subtitle}}</v-list-item-subtitle>
</template>

<script>
  export const subtitle = (contact = {}, type) => {
    if (type === 'address' && contact.address) {
      const {address} = contact;
      let lines = [address.street];
      if (address.extension) {
        lines.push(address.extension);
      }
      lines.push(`${address.country_code}-${address.postcode} ${address.city}`);
      if (address.district) {
        lines.push(address.district);
      }
      if (address.region) {
        lines.push(address.region);
      }
      if (address.pobox) {
        lines.push(address.pobox);
      }
      return lines.join(',\n');
    }
    return contact[type];
  };

  export default {
    name: 'ContactSubtitle',

    props: {
      contact: {
        type: Object,
        default: () => ({})
      },
      type: {
        type: String,
        default: ''
      }
    },

    computed: {
      subtitle() {
        return subtitle(this.contact, this.type);
      }
    }
  };
</script>
