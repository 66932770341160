<template>
  <div class="d-flex flex-column justify-center align-center h-100">
    <div v-if="!inbox" class="text--accent text-center w-100">
      <div v-if="loading">
        <v-progress-circular indeterminate color="accent" class="mb-2"></v-progress-circular>
        <p>Postfach wird geladen ...</p>
      </div>
      <div v-if="!loading && error" class="text--error">
        <div v-if="error === 404">
          <p>
            Postfach nicht gefunden.<br>
            <small>
              Entweder funktioniert der von Ihnen angeklickte Link nicht oder das Postfach wurde entfernt.
            </small>
          </p>
          <v-btn depressed color="primary" small :to="{name: 'inboxes'}">
            Zu meinen Postfächern
          </v-btn>
        </div>
        <div v-else>
          <p>Postfach konnte nicht geladen werden.</p>
          <div v-if="error === 500 || error === true">
            <v-btn depressed color="primary" small @click="refresh">Erneut versuchen</v-btn>
          </div>
        </div>
      </div>
    </div>

    <div v-if="inbox" class="w-100">
      <router-view :inbox="inbox"></router-view>
    </div>
  </div>
</template>

<script>
  import DetailMixin from '../mixins/detail-mixin';


  export default {
    name: 'Inbox',

    mixins: [
      DetailMixin({
        item: 'inbox',
        id: 'inboxId',
        operationId: 'InboxGet'
      })
    ]
  };
</script>
