import Vue from 'vue';

export const operationIdMap = {
  email: 'EmailsList',
  phone: 'PhonesList',
  address: 'AddressesList'
};

export const contactTypesMap = {
  address: 'addresses',
  email: 'emails',
  phone: 'phones'
};

const filterContacts = (contacts, inboxId) => {
  return contacts
      .filter((contact) => contact.inboxId === inboxId) // Filter by inboxId
      .map((contact) => { // Remove inboxId from contact.
        contact = {...contact};
        delete contact.inboxId;
        return contact;
      });
};

const replaceContacts = (contacts_state, inboxId, contacts_concat) => {
  return [
    ...contacts_state.filter((contact) => contact.inboxId !== inboxId), // Remove contacts with inboxId.
    ...(contacts_concat || []).map((contact) => ({...contact, inboxId})) // Concat new contacts (incl. inboxId).
  ];
};

export default {
  namespaced: true,

  state: {
    addresses: [],
    emails: [],
    phones: [],
    requests: {}
  },

  mutations: {
    replace_addresses(state, {inboxId, value: addresses}) {
      state.addresses = replaceContacts(state.addresses, inboxId, addresses);
    },

    replace_emails(state, {inboxId, value: emails}) {
      state.emails = replaceContacts(state.emails, inboxId, emails);
    },

    replace_phones(state, {inboxId, value: phones}) {
      state.phones = replaceContacts(state.phones, inboxId, phones);
    },

    set_request(state, {type, inboxId, request}) {
      const key = `${type}#${inboxId}`;
      state.requests = {...state.requests, [key]: request};
    }
  },

  getters: {
    addresses: (state) => (inboxId) => {
      return filterContacts(state.addresses, inboxId);
    },

    emails: (state) => (inboxId) => {
      return filterContacts(state.emails, inboxId);
    },

    phones: (state) => (inboxId) => {
      return filterContacts(state.phones, inboxId);
    },

    request: (state) => ({type, inboxId}) => {
      const key = `${type}#${inboxId}`;
      return state.requests[key];
    }
  },

  actions: {
    async load({commit, getters}, {type, inboxId}) {
      const active_request = getters.request({type, inboxId});
      if (active_request) {
        await active_request;
        return;
      }

      const mutationName = `replace_${contactTypesMap[type]}`;

      try {
        const operationId = operationIdMap[type];
        const request = Vue.apiOperation(operationId, {parameters: {inboxId}});
        commit('set_request', {type, inboxId, request});

        const {data} = await request;
        commit(mutationName, {inboxId, value: data.results});

      } catch (error) {
        commit(mutationName, {inboxId, value: []});
      }
      commit('set_request', {type, inboxId, request: null});
    }
  }
};
