<template>
  <router-link class="router-link d-block" :to="{name: 'document', params: {documentId: document.id}}">
    <v-card class="mb-4" hover>
      <div class="color" :style="{backgroundColor: color}"></div>
      <v-card-text class="d-flex align-start" @click="$emit('click')">
        <v-icon v-if="$vuetify.breakpoint.smAndUp" class="icon flex-grow-0 flex-shrink-0 mr-4">
          {{fileIcon}}
        </v-icon>
        <div class="flex">
          <div class="d-flex align-start">
            <v-card-title class="flex text--primary px-0 pt-0 pb-1">
              {{document.name}}
            </v-card-title>
            <v-icon v-if="statusIcon" :title="$t(`documents.type.${document.status}`)" small>{{statusIcon}}</v-icon>
            <v-icon
              v-if="document.receipt_required === 'SIGNATURE'"
              title="Erfordert eine Unterschrift" class="text--signature ml-2"
              small>
              fas fa-signature
            </v-icon>
            <v-icon
              v-if="document.shares && document.shares.length"
              title="Dokument freigegeben" class="text--primary ml-2"
              small>
              fas fa-share-alt
            </v-icon>
          </div>
          <div class="d-flex align-end">
            <v-card-subtitle class="flex py-0 pl-0">
              <span v-if="document.status === 'RECEIVED' && document.sender_address">
                Von <i>{{document.sender_address.name}}</i>
              </span>
              <span v-else-if="['SENT', 'DRAFT'].includes(document.status) && document.receiver_address && (document.receiver_address.name || document.receiver_address.raw)">
                An <i>{{document.receiver_address.name || document.receiver_address.raw[0]}}</i>
              </span>
              <br>
              <small>{{document.created | moment('L[,] LT [Uhr]')}}</small>
            </v-card-subtitle>
            <div class="flex-shrink-0">
              <DocumentStatus v-if="document.done || !document.opened" :document="document" />
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </router-link>
</template>

<script>
  import DocumentStatus from './DocumentStatus';
  import {icon as fileIcon} from '../../utils/file';

  export default {
    name: 'DocumentItem',

    components: {
      DocumentStatus
    },

    props: {
      document: {
        type: Object,
        default: () => ({})
      },
      color: {
        type: String,
        default: ''
      }
    },

    computed: {
      fileIcon() {
        const {document} = this;
        const fileName = document.files && document.files.length ? document.files[0].name : undefined;
        return fileIcon(fileName);
      },

      statusIcon() {
        const {document} = this;
        if (!document) {
          return null;
        }

        switch (document.status) {
          case 'RECEIVED':
            return 'far fa-inbox-in';

          case 'SENT':
            return 'far fa-inbox-out';

          case 'DRAFT':
            return 'far fa-pen';

          default:
            return '';
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/variables";

  .v-card {
    overflow: hidden;

    .color {
      position: absolute;
      left: 0;
      top: 0;
      width: 3px;
      height: 100%;
      background-color: $accent-light;
    }
  }

  .icon {
    width: 60px;
    height: 60px;
    color: $grey-color;
    background-color: $grey-background-color;
    border-radius: 50%;
  }

  li {
    margin-right: 25px;
  }
</style>
