<template>
  <div id="page-LandingPage">
    <header class="mt-5">
      <v-container class="d-flex justify-center">
        <div>
          <v-img
            src="/img/logo.svg"
            max-width="250"
          ></v-img>
        </div>

        <v-spacer :class="{'d-none': $vuetify.breakpoint.smAndDown}"></v-spacer>

        <div :class="{'d-none': $vuetify.breakpoint.smAndDown}">
          <v-btn
            class="mr-2"
            href="/sso"
            text
            rounded
          >
            Login
          </v-btn>

          <v-btn
            href="/sso"
            rounded
            outlined
          >
            Jetzt registrieren
          </v-btn>
        </div>
      </v-container>
    </header>

    <div class="main" :class="{'mt-8': $vuetify.breakpoint.lgAndUp}">
      <v-container>
        <v-row class="justify-center align-center">
          <v-col cols="12" md="8" lg="6">
            <v-img src="/img/product-image.svg"></v-img>
          </v-col>

          <v-col cols="12" md="4" lg="4">
            <span
              class="font-weight-medium mb-4 d-block"
              :class="[$vuetify.breakpoint.mdAndUp ? 'text-left display-2' : 'text-center', {'display-1': $vuetify.breakpoint.mdAndDown}]"
            >
              DigitalesPostfach. Post, sicher und kostenlos online empfangen.
            </span>

            <span
              class="body-1 mb-12 d-block"
              :class="$vuetify.breakpoint.mdAndUp ? 'text-left' : 'text-center'"
            >
              Ab sofort Post digital, sicher, ohne Werbung und komplett kostenlos mit DigitalesPostfach empfangen.
            </span>

            <div
              class="d-flex flex-column"
              :class="$vuetify.breakpoint.mdAndUp ? 'align-start' : 'justify-center'"
            >
              <v-btn
                class="mb-3"
                href="/sso"
                color="white"
                rounded
                depressed
                x-large
              >
                <v-img src="/img/logo.png" max-width="20" class="mr-2"></v-img>
                mit Xidras.io Konto einloggen
              </v-btn>

              <v-btn
                class="white--text"
                href="https://www.xidras.io/services/XidrasDigitalesPostfach/"
                target="_black"
                color="black"
                rounded
                depressed
                large
              >
                Mehr Informationen >>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <footer>
      <v-container class="text-center">
        Copyright &copy; {{year}} Xidras GmbH
      </v-container>
    </footer>
  </div>
</template>

<script>
  export default {
    name: 'LandingPage',

    computed: {
      year() {
        return this.$moment().year();
      }
    }
  };
</script>

<style lang="scss">
  @import '../assets/scss/landingpage.scss';
</style>
