<template>
  <v-chip pill :small="small" :class="'status-' + status">{{$t(`documents.status.${status}`)}}</v-chip>
</template>

<script>
  export default {
    name: 'DocumentStatus',

    props: {
      document: {
        type: Object,
        default: () => ({})
      },
      small: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      status() {
        const {document} = this;
        if (document.done) {
          return 'done';
        }
        if (document.opened) {
          return 'read';
        }
        return 'unread';
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/variables";

  @mixin chip-color($color) {
    color: $color;
    background-color: rgba($color, 0.1);
  }

  .v-chip.status-unread {
    @include chip-color($info-light);
  }

  .v-chip.status-done {
    @include chip-color($success-light);
  }
</style>
