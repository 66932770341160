<template>
  <div>
    <p>&nbsp;</p>
    <v-text-field
      ref="input-focus"
      v-model="data.name"
      :rules="[rules.required]"
      type="text"
      name="name"
      label="Name"
      outlined
      dense
    ></v-text-field>
    <v-text-field
      v-model="data.email"
      :rules="[rules.required, rules.email]"
      type="email"
      name="email"
      :label="$t('contacts.email.single')"
      outlined
      dense
    ></v-text-field>
  </div>
</template>

<script>
  import * as rules from '../../../utils/rules';

  export default {
    name: 'EmailFields',

    props: {
      value: {
        type: Object,
        default: () => ({
          name: '',
          email: ''
        })
      }
    },

    data() {
      return {
        rules,
        data: {}
      };
    },

    watch: {
      value() {
        if (!this._ignoreValueChange) {
          this._setData();
        }
      }
    },

    created() {
      this._setData();
      this.$watch('data', (data) => this.$emit('input', data), {deep: true});
    },

    methods: {
      _setData() {
        const value = this.value;
        const value_default = this.$options.props.value.default;

        this._ignoreValueChange = true;
        if (value) {
          this.data = {...value_default, ...value};
        } else {
          this.data = {...value_default};
        }
        this.$nextTick(() => this._ignoreValueChange = false);
      }
    }
  };
</script>
