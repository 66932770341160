export default {
  methods: {
    /**
     * Fire event on window
     *
     * @param {String} name event name
     * @param {any} detail
     */
    globalEvent(name, detail) {
      const event = new CustomEvent(name, {detail});
      window.dispatchEvent(event);
    }
  }
};
