<script>
  import InboxCreate from './InboxCreate';
  import {inbox_default} from './InboxCreate.vue';
  import DetailMixin from '../mixins/detail-mixin';

  const detailMixin = DetailMixin({
    item: 'inbox',
    id: 'inboxId',
    operationId: 'InboxGet'
  });

  export const inboxUpdateBody = (inbox = {}) => {
    delete inbox.unread;
    delete inbox.address_variants;
    if (inbox.sender_data) {
      delete inbox.sender_data.allowedToSend;
    }
    return inbox;
  };

  export default {
    name: 'InboxUpdate',

    extends: InboxCreate,

    mixins: [
      detailMixin
    ],

    data() {
      return {
        stepsDisabled: false,
        inbox: null
      };
    },

    created() {
      this.$watch('inbox', () => this._onChange(), {deep: true});
    },

    methods: {
      async refresh() {
        const inbox = await detailMixin.methods.refresh.apply(this);
        if (inbox) {
          this.inbox = inboxUpdateBody({...inbox_default, ...inbox});
          this.inbox_initial = JSON.parse(JSON.stringify(this.inbox));
        } else {
          this.inbox = null;
          this.inbox_initial = {...inbox_default};
        }

        this._onChange();
      },

      _onChange() {
        this.saveDisabled = JSON.stringify(this.inbox) === JSON.stringify(this.inbox_initial);
      }
    }
  };
</script>
