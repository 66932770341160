<template>
  <div>
    <v-text-field
      v-model="data.pre_nominal_title"
      type="text"
      name="pre_nominal_title"
      label="Vorangestellte Titel"
      outlined
      dense
    ></v-text-field>
    <v-row>
      <v-col cols="12" sm="6" class="py-0 pr-sm-1">
        <v-text-field
          ref="input-focus"
          v-model="data.firstName"
          :rules="[rules.required]"
          type="text"
          name="firstName"
          label="Vorname *"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" class="py-0 pl-sm-1">
        <v-text-field
          v-model="data.lastName"
          :rules="[rules.required]"
          type="text"
          name="lastName"
          label="Nachname *"
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-text-field
      v-model="data.post_nominal_title"
      type="text"
      name="post_nominal_title"
      label="Nachgestellte Titel"
      outlined
      dense
    ></v-text-field>
  </div>
</template>

<script>
  import * as rules from '../../../utils/rules';

  export default {
    name: 'NameFields',

    props: {
      value: {
        type: Object,
        default: () => ({
          pre_nominal_title: '',
          firstName: '',
          lastName: '',
          post_nominal_title: ''
        })
      }
    },

    data() {
      return {
        rules,
        data: {}
      };
    },

    watch: {
      value() {
        if (!this._ignoreValueChange) {
          this._setData();
        }
      }
    },

    created() {
      this._setData();
      this.$watch('data', (data) => this.$emit('input', data), {deep: true});
    },

    methods: {
      _setData() {
        const value = this.value;
        const value_default = this.$options.props.value.default;

        this._ignoreValueChange = true;
        if (value) {
          this.data = {...value_default, ...value};
        } else {
          this.data = {...value_default};
        }
        this.$nextTick(() => this._ignoreValueChange = false);
      }
    }
  };
</script>

<style scoped>
  .row {
    margin: 0 -12px;
  }
</style>
