export default {

  data() {
    return {
      opened: false,
      openedPath: null,
      maxWidth: 500
    };
  },

  watch: {
    opened(opened) {
      if (opened) {
        this.openedPath = this.$route.fullPath;
      } else {
        this.openedPath = null;
      }
    },

    '$route.fullPath'(path) {
      if (this.openedPath && this.openedPath !== path) {
        this.close();
      }
    }
  },

  methods: {
    open() {
      this.opened = true;
    },

    close() {
      this.opened = false;
    },

    toggle() {
      this.opened = !this.opened;
    }
  }
};
