<template>
  <v-dialog v-model="opened" :max-width="maxWidth" scrollable>
    <v-card>
      <v-card-title class="headline">Verifizierung von Kontaktdaten</v-card-title>
      <v-card-text class="pb-0 text--primay-text">
        <p>
          Bevor Sie Ihre angegebenen Kontaktdaten verwenden können, müssen Sie
          bestätigten, dass Sie mit diesen Daten erreichbar sind.
        </p>

        <h3 class="mt-4 mb-3">Wie funktioniert die Verifizierung?</h3>
        <p>
          Sobald Sie einen Kontakt erstellen, schicken wir eine Nachricht
          an die angegebenen Daten.
        </p>
        <p>
          Diese Nachricht beinhaltet einen Link zur Verifizierungsseite, eine
          einmalig gültige 4-stelligen PIN, sowie Beschreibung wie Sie die
          Verifizierung abschließen.
        </p>
        <p>
          Öffnen Sie den Link und geben Sie die PIN ein, anschließend ist dieser
          Kontakt verifiziert und kann in Ihrem Postfach verwendet werden.
        </p>


        <h3 class="mt-4 mb-3">Welche Arten von Verifizierungen gibt es?</h3>

        <h4 class="mt-3 mb-2">Adresse</h4>
        <p>
          Wir senden Ihnen ein Verifizierungsschreiben per Post zu.<br>
          Der Brief sollte binnen 2 bis 3 Werktagen in Ihrem Postkasten sein.
        </p>

        <h4 class="mt-3 mb-2">E-Mail-Adresse</h4>
        <p>
          Sie erhalten die Details zur Verifizierung als E-Mail an die
          angegebene E-Mail-Adresse innerhalb weniger Minuten.
        </p>

        <h4 class="mt-3 mb-2">Telefonnummer (mobil)</h4>
        <p>
          Wir übermitteln Ihnen die Verifizierungsdaten per SMS an die
          angegebene Telefonnummer innerhalb weniger Minuten.
        </p>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed @click="close">
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import DialogMixin from '../../mixins/dialog-mixin.js';
  import eventBus from '../../utils/event-bus.js';


  export default {
    name: 'InboxVerificationInfo',

    mixins: [
      DialogMixin
    ],

    mounted() {
      eventBus.$on('open-inbox-verification-info', this.open);
    },

    destroyed() {
      eventBus.$off('open-inbox-verification-info', this.open);
    }
  };
</script>
