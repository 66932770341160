<template>
  <v-dialog v-model="opened" :max-width="maxWidth" scrollable>
    <v-card>
      <v-card-text class="px-0 py-0">
        <v-time-picker v-if="opened" v-model="time" format="24hr" max-width class="v-card--no-shadow"></v-time-picker>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed @click="close">
          Abbrechen
        </v-btn>
        <v-btn color="success" depressed @click="_save">
          Übernehmen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import DialogMixin from '../../mixins/dialog-mixin.js';

  export default {
    name: 'NotificationTime',

    mixins: [
      DialogMixin
    ],

    data() {
      return {
        maxWidth: 290,
        time: '18:00',
        notification: {}
      };
    },

    methods: {
      open(notification) {
        this.notification = notification;
        this.time = notification.notify_daily_at;
        this.opened = true;
      },


      _save() {
        this.$set(this.notification, 'notify_daily_at', this.time);
        this.close();
      }
    }
  };
</script>
