let emailInput = null;


export const required = (value) => {
  return !!value || 'Feld erforderlich';
};


export const email = (value) => {
  if (!value) {
    return true; // Not required.
  }

  if (!emailInput) {
    emailInput = document.createElement('input');
    emailInput.type = 'email';
  }

  emailInput.value = value;
  let valid;
  try {
    valid = emailInput.validity.valid;
  } catch (error) {
    valid = true;
  }
  return !!valid || 'E-Mail ungültig';
};


export const phone = (value = '') => {
  if (!value) {
    return true; // Not required.
  }

  const valid = !!value.match(/^[0-9]([0-9\s]+)[0-9]$/);
  return !!valid || 'Ungültige Telefonnummer';
};


export const postcode = (value = '') => {
  if (!value) {
    return true; // Not required.
  }

  const valid = !!value.match(/^[0-9]{4,5}$/);
  return !!valid || 'Ungültige PLZ';
};


export const taxNumber = (country_code) => {

  // https://en.wikipedia.org/wiki/VAT_identification_number
  const countries = {
    AT: '^ATU[\\s]{0,1}[0-9]{8}$', // e.g. `ATU99999999`, `ATU 99999999`
    DE: '^DE[\\s]{0,1}[0-9]{9}$', // e.g. `DE999999999`, `DE 999999999`
    CH: '^CHE[-|\\s]{0,1}[0-9]{3}\\.[0-9]{3}\\.[0-9]{3}[\\s]{0,1}(TVA|MWST|IVA)$'
    // e.g. `CHE-123.456.788 TVA`, `CHE 123.456.788 TVA`, `CHE123.456.788TVA`
  };

  const pattern = countries[country_code] ||
    Object.values(countries).map((p) => `(${p})`).join('|');

  const regex = new RegExp(pattern);

  return (value = '') => {
    if (!value) {
      return true; // Not required.
    }
    const valid = !!value.match(regex);
    return !!valid || 'Ungültige USt-IdNr. / VAT Nummer';
  };
};


export const url = (value = '') => {
  if (!value) {
    return true; // Not required.
  }

  const valid = !!value.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/);
  return !!valid || 'Ungültige URL';
};


export default {
  required,
  email,
  phone,
  postcode,
  taxNumber
};
