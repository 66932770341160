<template>
  <div>
    <h3 class="mt-2 mb-6">Freigegeben für</h3>
    <v-card class="shares v-card--no-shadow">
      <v-card-text>
        <table class="table mb-0" :class="{'table-responsive': responsiveTable}">
          <thead>
            <tr>
              <th>E-Mail</th>
              <th>Status</th>
              <th>Datum</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(share, i) in shares" :key="i">
              <td><i>{{share.email}}</i></td>
              <td><DocumentStatus small :document="share" /></td>
              <td>{{share.created | moment('L[,] LT [Uhr]')}}</td>
              <td>
                <v-btn
                  icon
                  x-small
                  color="error"
                  @click="$refs.delete.open(share)"
                >
                  <v-icon>far fa-minus-circle</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
    </v-card>

    <DocumentShareDelete ref="delete" @delete="$emit('delete')" />
  </div>
</template>

<script>
  import {ResizeSensor} from 'css-element-queries';
  import DocumentStatus from './DocumentStatus';
  import DocumentShareDelete from './DocumentShareDelete';

  export default {
    name: 'DocumentShares',

    components: {
      DocumentStatus,
      DocumentShareDelete
    },

    props: {
      shares: {
        type: Array,
        default: () => []
      }
    },

    data() {
      return {
        responsiveTable: true
      };
    },

    mounted() {
      const element = this.$el;
      const table = element.querySelector('.table thead');

      new ResizeSensor(element, () => {
        this.responsiveTable = true;
        requestAnimationFrame(() => {
          this.responsiveTable = element.clientWidth - 32 <= table.clientWidth;
        });
      });
    }
  };
</script>

<style lang="scss" scoped>
  .shares {
    .v-card__text {
      @import '~bootstrap/scss/_functions';
      @import '~bootstrap/scss/_variables';
      @import '~bootstrap/scss/_mixins';
      @import '~bootstrap/scss/_tables';

      table {
        border-collapse: collapse;
        text-align: left;
      }

      .table th,
      .table td {
        border-top: none;
      }
    }
  }
</style>
