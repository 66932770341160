<template>
  <div class="documents-container d-flex">
    <v-navigation-drawer
      v-show="!(mediumScreen && !drawerOpen)"
      ref="drawer"
      v-model="drawerOpen"
      class="flex-shrink-0"
      :width="$vuetify.breakpoint.smAndUp ? 450 : '100%'"
      :style="{'height': mediumScreen ? 'auto' : '100vh'}"
      :absolute="!mediumScreen"
      :temporary="!mediumScreen"
      :permanent="mediumScreen || !documentId"
    >
      <v-toolbar flat>
        <v-btn
          icon
          depressed
          title="Zu meinen Postfächern"
          :class="{'mr-2': $vuetify.breakpoint.mdAndUp}"
          :to="{name: 'inboxes'}"
        >
          <v-icon small>far fa-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title style="background-color: transparent;" class="pl-0">
          {{inbox.name}}
          <v-card-subtitle
            class="py-0 pl-0 mt-0"
            :class="{
              'text--secondary': inbox.unread === 0,
              'font-weight-medium text--info': inbox.unread > 0
            }"
          >
            {{inbox.unread}}
            ungelesene<template v-if="inbox.unread === 1">s</template>
            Dokument<template v-if="inbox.unread === 0 || inbox.unread > 1">e</template>
          </v-card-subtitle>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          v-if="inboxIncomplete"
          :to="{name: 'inbox-update', params: {inboxId: inbox.id}, query: {step: 2}}"
          title="Unvollständiges Postfach"
          color="error"
          icon
        >
          <v-icon>fas fa-exclamation-triangle</v-icon>
        </v-btn>
        <v-btn
          v-else-if="inboxUnverified"
          :to="{name: 'inbox-update', params: {inboxId: inbox.id}, query: {step: 2}}"
          title="Nicht verifizierte Kontaktdaten"
          color="warning"
          icon
        >
          <v-icon>fas fa-info-circle</v-icon>
        </v-btn>

        <InboxActions :inbox="inbox">
          <template #prepend-items>
            <v-list-item @click="refresh">
              <v-list-item-icon>
                <v-icon>fal fa-sync</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Aktualisieren</v-list-item-title>
            </v-list-item>
          </template>
        </InboxActions>
      </v-toolbar>

      <v-container
        id="page-documents-list"
        :class="{'d-flex align-stretch': !documents.length}"
        fluid
      >
        <div v-if="!documents.length" class="text--accent d-flex align-center justify-center text-center w-100">
          <div v-if="loading">
            <v-progress-circular
              indeterminate
              color="accent"
              class="mb-2"
            ></v-progress-circular>
            <p>Dokumente werden geladen ...</p>
          </div>
          <div v-if="!loading && error" class="text--error">
            <p>Dokumente konnten nicht geladen werden.</p>
            <v-btn depressed color="primary" small @click="refresh">Erneut versuchen</v-btn>
          </div>
          <div v-if="!loading && !error">
            Keine Dokumente vorhanden.
          </div>
        </div>

        <DocumentItem
          v-for="(document, index) in documents"
          :key="index"
          :document="document"
          :color="inbox.color"
          @click="!mediumScreen ? drawerOpen = false : null"
        />

        <v-pagination
          v-if="documents.length && pages_count > 1"
          :value="page"
          :length="pages_count"
          :total-visible="7"
          @input="$event !== page && $router.replace({name: $route.name, query: {...$route.query, page: $event}})"
        ></v-pagination>
      </v-container>
    </v-navigation-drawer>

    <div class="flex d-flex align-stretch">
      <router-view
        v-if="documentId"
        :drawer-open="drawerOpen"
        class="w-100 align-stretch"
      />

      <v-container v-else id="page-no-document" class="d-flex flex-column justify-center align-center" fluid>
        <v-icon class="mb-5">fad fa-file-pdf</v-icon>
        <div class="text-center">
          Kein Dokument ausgewählt.
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
  import ListMixin from '../mixins/list-mixin';
  import InboxActions from '../components/inbox/InboxActions';
  import DocumentItem from '../components/document/DocumentItem';
  import {incomplete as inboxIncomplete, unverified as inboxUnverified} from '../utils/inbox';

  export default {
    name: 'Documents',

    components: {
      InboxActions,
      DocumentItem
    },

    mixins: [
      ListMixin({
        items: 'documents',
        id: 'documentId',
        operationId: 'DocumentsList',
        parameters() {
          return {inboxId: this.$route.params.inboxId};
        },
        limit: 10
      })
    ],

    props: {
      inbox: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        drawerOpen: this.$vuetify.breakpoint.mdAndUp
      };
    },

    computed: {
      mediumScreen() {
        return this.$vuetify.breakpoint.mdAndUp;
      },

      inboxIncomplete() {
        return inboxIncomplete(this.inbox);
      },

      inboxUnverified() {
        return inboxUnverified(this.inbox);
      }
    },

    watch: {
      documentId(documentId, prevDocumentId) {
        if (!documentId && prevDocumentId) {
          this.drawerOpen = true;
        }
      }
    },

    mounted() {
      window.addEventListener('toggle-drawer', this.toggleDrawer);
      window.addEventListener('keydown', this._keydownListener);
    },

    destroyed() {
      window.removeEventListener('toggle-drawer', this.toggleDrawer);
      window.removeEventListener('keydown', this._keydownListener);
    },

    methods: {
      toggleDrawer() {
        this.drawerOpen = !this.drawerOpen;
      },

      nextPage() {
        this.changePage(this.page + 1);
      },

      prevPage() {
        this.changePage(this.page - 1);
      },

      changePage(page) {
        const {$router, $route, pages_count} = this;
        if (page > 0 && page <= pages_count) {
          $router.replace({...$route, query: {...$route.query, page}});
        }
      },

      nextDocument() {
        const {documents, documentId} = this;
        let index = documents.findIndex((doc) => doc.id === documentId);

        index = index !== -1 ? index + 1 : 0;
        if (index < documents.length) {
          this.changeDocument(documents[index].id);
        }
      },

      prevDocument() {
        const {documents, documentId} = this;
        let index = documents.findIndex((doc) => doc.id === documentId);

        index = index !== -1 ? index - 1 : 0;
        if (documents.length && index >= 0) {
          this.changeDocument(documents[index].id);
        }
      },

      changeDocument(id) {
        const {$router, $route} = this;
        $router.replace({
          ...$route,
          name: 'document',
          params: {...$route.params, documentId: id}
        });
      },

      _keydownListener(e) {
        switch (e.code) {
          case 'ArrowRight':
            this.nextPage();
            break;

          case 'ArrowLeft':
            this.prevPage();
            break;

          case 'ArrowDown':
            e.preventDefault();
            this.nextDocument();
            break;

          case 'ArrowUp':
            e.preventDefault();
            this.prevDocument();
            break;

          default:
            break;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .documents-container {
    min-height: 100vh;
  }

  .v-navigation-drawer {
    background-color: #f8f9fb;
    z-index: 5;
  }

  #page-no-document {
    min-height: 100vh;

    &,
    .v-icon {
      color: $accent-light;
    }

    .v-icon {
      font-size: 220px;
    }
  }
</style>
