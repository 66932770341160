<template>
  <div class="colors">
    <div
      v-for="(color, index) in colors"
      :key="index"
      :class="{'color-selected': value === color}"
      :style="{backgroundColor: color}"
      class="color"
      @click="$emit('input', color)">
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ColorSelector',

    props: {
      value: {
        type: String,
        default: '#ad1457'
      }
    },

    data() {
      return {
        colors: [
          '#ad1457',
          '#d81b60',
          '#d50202',
          '#e67b73',
          '#f4511d',
          '#ef6c01',
          '#f09302',
          '#f6bf25',
          '#e4c442',
          '#c0ca33',
          '#74a83e',
          '#31b679',
          '#0b8043',
          '#069688',
          '#039be5',
          '#4285f4',
          '#3f51b5',
          '#7986cb',
          '#b39ddb',
          '#9e69af',
          '#8e24aa',
          '#795548',
          '#616161',
          '#a79b8e'
        ]
      };
    },

    watch: {
      value() {
        this._setDefaultValue();
      }
    },

    mounted() {
      this._setDefaultValue();
    },

    methods: {
      _setDefaultValue() {
        if (!this.value || !this.colors.includes(this.value)) {
          this.$emit('input', this.$options.props.value.default);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  $color-size: 33px;
  $color-margin: 8px;

  .colors {
    max-width: ($color-size + $color-margin) * 12;
    margin-right: -$color-margin;
  }

  .color {
    display: inline-block;
    position: relative;
    width: $color-size;
    height: $color-size;
    border-radius: 20%;
    cursor: pointer;
    margin-right: $color-margin;
  }

  .color.color-selected::after {
    display: block;
    position: absolute;
    width: $color-size;
    height: $color-size;
    top: $color-size / 2 - $color-size / 2;
    left: $color-size / 2 - $color-size / 2;
    font-family: 'Font Awesome 5 Pro', sans-serif;
    content: "\f058";
    color: #fff;
    font-style: normal;
    font-weight: 900;
    font-variant: normal;
    text-rendering: auto;
    text-align: center;
    line-height: $color-size;
    -webkit-font-smoothing: antialiased;
  }
</style>
