<template>
  <div>
    <p>&nbsp;</p>
    <v-text-field
      ref="input-focus"
      v-model="data.name"
      :rules="[rules.required]"
      type="text"
      name="name"
      label="Name"
      outlined
      dense
    ></v-text-field>
    <v-row>
      <v-col cols="12" sm="4" class="py-0 pr-sm-1">
        <v-select
          v-model="prefix"
          :rules="[rules.required]"
          :items="prefixes"
          name="prefix"
          label="Vorwahl"
          outlined
          dense>
        </v-select>
      </v-col>
      <v-col cols="12" sm="8" class="py-0 pl-sm-1">
        <v-text-field
          v-model="number"
          :rules="[rules.required, rules.phone]"
          type="text"
          name="number"
          :label="$t('contacts.phone.single')"
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import rules from '../../../utils/rules';

  export default {
    name: 'PhoneFields',

    props: {
      value: {
        type: Object,
        default: () => ({
          name: '',
          phone: '',
          country_code: ''
        })
      }
    },

    data() {
      return {
        rules,
        prefixes: [
          {text: 'AT (+43)', value: '+43', country_code: 'AT'},
          {text: 'DE (+49)', value: '+49', country_code: 'DE'},
          {text: 'CH (+41)', value: '+41', country_code: 'CH'}
        ],
        prefix: '+43',
        number: '',
        data: {}
      };
    },

    watch: {
      value() {
        if (!this._ignoreValueChange) {
          this._setData();
        }
      }
    },

    created() {
      this._setData();

      this.$watch('data', (data) => this.$emit('input', data), {deep: true});

      this.$watch((self) => [self.prefix, self.number], ([prefix, number]) => {
        // Set phone.
        this.$set(this.data, 'phone', prefix + number);

        // Set country code.
        const prefixData = this.prefixes.find((p) => p.value === prefix);
        if (prefixData) {
          this.$set(this.data, 'country_code', prefixData.country_code);
        }
      });
    },

    methods: {
      _setData() {
        const value = this.value;
        const value_default = this.$options.props.value.default;

        this._ignoreValueChange = true;
        if (value) {
          this.data = {...value_default, ...value};
        } else {
          this.data = {...value_default};
        }
        this.$nextTick(() => this._ignoreValueChange = false);
      }
    }
  };
</script>

<style scoped>
  .row {
    margin: 0 -12px;
  }
</style>
