<template>
  <div>
    <div v-for="(type, index) in types" :key="index">
      <v-checkbox
        v-model="enabled"
        color="primary"
        :value="type.name"
        :label="`${type.title} Benachrichtigungen aktivieren`"
        class="mt-0"
        dense
      ></v-checkbox>
      <div
        v-show="enabled.includes(type.name)"
        class="intend"
      >
        <div v-if="type.value.length" class="notifications d-flex justify-end">
          <span class="flex">Wann möchten Sie benachrichtigt werden?</span>
          <span class="notification">bei jeder Nachricht</span>
          <span class="notification notify_daily_at">einmal am Tag</span>
        </div>
        <Contacts
          v-model="type.value"
          :type="type.contactType"
          :inbox="inbox"
          :value-contact="valueContact(type.contactType)"
          multiple
        >
          <template #list-item="props">
            <v-list-item-title>{{props.contact[type.contactType].name}}</v-list-item-title>
            <v-list-item-subtitle>{{props.contact[type.contactType][type.contactType]}}</v-list-item-subtitle>
          </template>
          <template #list-item-append="props">
            <v-radio-group v-model="props.contact.notify_immediate" class="pt-0 mt-0" dense>
              <div class="d-flex align-center justify-center">
                <div class="notification">
                  <v-radio color="primary" :value="true"></v-radio>
                </div>
                <div class="notification notify_daily_at d-flex align-center justify-center">
                  <v-radio color="primary" :value="false"></v-radio>
                  <div class="d-flex align-center justify-center" @click="props.contact.notify_immediate = false">
                    <small class="ml-2 mr-1">um</small>
                    <v-btn outlined small class="px-1 py-1" @click="$refs.time.open(props.contact)">
                      {{props.contact.notify_daily_at || '--:--'}}
                      <v-icon small color="accent" class="ml-1">fas fa-caret-down</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-radio-group>
          </template>
        </Contacts>
      </div>
    </div>

    <NotificationTime ref="time" />
  </div>
</template>

<script>
  import Contacts from '../contacts/Contacts';
  import NotificationTime from './NotificationTime';

  export const types = [
    {name: 'EMAIL', contactType: 'email', title: 'E-Mail', value: []},
    {name: 'SMS', contactType: 'phone', title: 'SMS', value: []}
    // ,{name: 'WHATSAPP', contactType: 'phone', title: 'WhatsApp', value: []}
  ];

  export default {
    name: 'Notifications',

    components: {
      Contacts,
      NotificationTime
    },

    props: {
      value: {
        type: Array,
        default: () => []
      },
      inbox: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        types,
        enabled: [],
        valueContact: (type) => (value) => value[type]
      };
    },

    watch: {
      value(value, prevValue) {
        if (JSON.stringify(value) !== JSON.stringify(prevValue)) {
          this._setValues(value);
        }
      },

      enabled(enabled) {
        if (this._ignore_enabled_change) {
          return;
        }
        const value = this.value.map((notification) => {
          return {
            ...notification,
            enabled: enabled.includes(notification.type)
          };
        });
        this.$emit('input', value);
      }
    },

    created() {
      // Watch for type value changes `types[x].value`.
      this.types.forEach((type, tIndex) => {
        this.$watch(`types.${tIndex}.value`, () => this._updateValue(type));
      });

      this._setValues(this.value);
    },

    methods: {
      _setValues(value) {
        // Set type values `types[x].value`.
        this._ignore_typeValue_change = true;
        this.types.forEach((type) => {
          type.value = value.filter((notification) => notification.type === type.name);
        });
        this.$nextTick(() => this._ignore_typeValue_change = false);

        // Set enabled.
        const enabled = [];
        this.types.forEach((type) => {
          if (value.find((n) => n.type === type.name && n.enabled)) {
            enabled.push(type.name);
          }
        });
        this._ignore_enabled_change = true;
        this.enabled = enabled;
        this.$nextTick(() => this._ignore_enabled_change = false);
      },

      _updateValue(type) {
        if (this._ignore_typeValue_change) {
          return;
        }
        const {contactType} = type;

        type.value.forEach((notification) => {
          if (typeof notification[contactType] !== 'object') {
            // Set notification data.
            this.$set(notification, contactType, {
              [type.contactType]: notification[type.contactType],
              id: notification.id,
              name: notification.name,
              verified: notification.verified,
              status: notification.status
            });
            // Clean-up keys.
            const validKeys = [contactType];
            for (const key in notification) {
              if (!validKeys.includes(key)) {
                delete notification[key];
              }
            }
          }

          this.$set(notification, 'type', type.name);

          if (notification.enabled === undefined) {
            this.$set(notification, 'enabled', true);
          }
          if (notification.notify_immediate === undefined) {
            const immediate = type.name !== 'SMS';
            this.$set(notification, 'notify_immediate', immediate);
          }
          if (notification.notify_daily_at === undefined) {
            this.$set(notification, 'notify_daily_at', '18:00');
          }
        });

        // Remove current notification type from value.
        let value = this.value.filter((notification) => notification.type !== type.name);
        value = value.concat(type.value); // Concat updated notifications.
        this.$emit('input', value);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .intend {
    margin-left: 34px;
  }

  .notifications {
    font-size: 12px;
    margin-top: -18px;
    margin-right: 56px;
  }

  .notification {
    min-width: 120px;
    text-align: center;

    &.notify_daily_at {
      min-width: 140px;
    }
  }
</style>
