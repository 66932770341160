<template>
  <v-dialog v-model="opened" :persistent="loading" :max-width="maxWidth" scrollable>
    <v-card>
      <v-card-title class="headline">
        Freigabe für "{{share.emails[0]}}" entfernen?
      </v-card-title>
      <v-alert v-if="error" type="error" text>
        Freigabe konnte nicht entfernt werden.<br>
        Bitte versuchen Sie es später noch einmal.
      </v-alert>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed :disabled="loading" @click="close">
          Abbrechen
        </v-btn>
        <v-btn color="success" depressed :loading="loading" @click="this.delete">
          Ja
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import DialogMixin from '../../mixins/dialog-mixin.js';

  export default {
    name: 'DocumentShareDelete',

    mixins: [
      DialogMixin
    ],

    data() {
      return {
        share: {},
        opened: false, // DialogMixin
        loading: false,
        error: false
      };
    },

    watch: {
      opened(opened) {
        if (!opened) {
          this.error = false;
        }
      }
    },

    methods: {
      open(share) {
        if (share) {
          this.share = share;
          this.opened = true;
        }
      },

      async delete() {
        if (this.loading) {
          return;
        }

        this.error = false;
        this.loading = true;

        try {
          const {share} = this;
          await this.$apiOperation('DocumentShareDelete', {
            parameters: {shareId: share.id},
            requestBody: {emails: [share.emails[0]]} // Needed for events.
          });

          this.$emit('delete');
          this.loading = false;
          this.close(); // DialogMixin

        } catch (error) {
          this.loading = false;
          this.error = true;
        }
      }
    }
  };
</script>
