<template>
  <v-dialog v-model="opened" :persistent="loading" :max-width="maxWidth" scrollable>
    <v-card>
      <v-card-title class="headline">
        {{$t(`contacts.${type}.single`)}} entfernen?
      </v-card-title>
      <v-alert v-if="error" type="error" text>
        {{$t(`contacts.${type}.single`)}} konnte nicht entfernt werden.<br>
        Bitte versuchen Sie es später noch einmal.
      </v-alert>
      <v-card-text
        v-if="contact.verified && locales[type] && locales[type].verifyType"
        class="pb-0"
      >
        Wenn Sie diese {{$t(`contacts.${type}.single`)}} entfernen, verfällt auch die Verifizierung.<br>
        Falls Sie diese {{$t(`contacts.${type}.single`)}} wieder hinzufügen möchten,
        müssen Sie diese erneut per {{$t(`contacts.${type}.verifyType`)}} verifizieren.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed :disabled="loading" @click="close">
          Abbrechen
        </v-btn>
        <v-btn color="error" depressed :loading="loading" @click="this.delete">
          Ja
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import locales from '../../locales/de';
  import DialogMixin from '../../mixins/dialog-mixin.js';

  export default {
    name: 'ContactDelete',

    mixins: [
      DialogMixin
    ],

    props: {
      type: {
        type: String,
        default: 'email'
      },
      inbox: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        contact: {},
        opened: false, // DialogMixin
        loading: false,
        error: false,
        locales: locales.contacts
      };
    },

    watch: {
      opened(opened) {
        if (!opened) {
          this.error = false;
        }
      }
    },

    methods: {
      open(contact) {
        if (contact) {
          this.contact = contact;
          this.opened = true;
        }
      },

      async delete() {
        if (this.loading) {
          return;
        }

        this.error = false;
        this.loading = true;

        try {
          const {type} = this;
          const operationId = type.substring(0, 1).toUpperCase() + type.substring(1) + 'Delete';

          await this.$apiOperation(operationId, {
            parameters: {
              [type + 'Id']: this.contact.id,
              inboxId: this.inbox.id
            }
          });

          this.$emit('delete', this.contact.id);
          this.loading = false;
          this.close(); // DialogMixin

        } catch (error) {
          this.loading = false;
          this.error = true;
        }
      }
    }
  };
</script>
