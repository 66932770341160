<template>
  <fragment>
    <div class="text-center w-100">
      <div class="mb-1">
        <img class="icon" src="/img/icon-sender-seal.png" @click.prevent="toggle" />
      </div>
      <v-btn color="primary" outlined @click="toggle">Information zur Prüfung</v-btn>
    </div>

    <v-dialog v-model="opened" :max-width="maxWidth" scrollable>
      <v-card>
        <v-card-text class="pb-0">
          <div class="text-center my-6">
            <img class="img-fluid" src="/img/verify.svg" width="400" />
          </div>

          <h2 class="mb-3">Informationen zur Verifizierung von Absendern</h2>

          <div class="mb-8">
            <p>
              Wir überprüfen die angegebene Steuernummer und Firmenname mit
              den zugehörigen Finanzamtsinformationen in der EU.
            </p>
          </div>

          <div class="mb-6">
            <h3 class="mb-1">Verifizierung von <strong>E-Mail-Adressen</strong></h3>
            <p>
              Wir kontaktieren den Absender über die angegebenen
              E-Mail-Adresse und überprüfen damit, ob er Zugriff und die
              nötigen Berechtigungen für diese Adresse hat.
            </p>
          </div>

          <div class="mb-6">
            <h3 class="mb-1">Prüfung von <strong>Telefonnummern</strong></h3>
            <p>
              Mit einem Telefonanruf oder SMS auf der angegebene Telefonnummer,
              überprüfen wir, ob diese korrekt ist und ob der Absender an
              dieser Telefonnummer erreichbar ist.
            </p>
          </div>

          <div>
            <h3 class="mb-1">Echtheitsprüfung von <strong>Postanschriften</strong></h3>
            <p class="mb-0">
              Um die Echtheit der Angaben zur Postanschrift zu bestätigen,
              senden wir dem Absender einen Brief an die angegebene
              Anschrift. In diesem Brief ist ein PIN-Code enthalten, der zur
              Bestätigung in unserem System eingegeben werden muss. Damit
              stellen wir sicher, dass der Absender eine korrekte Anschrift
              angegeben hat und dass er an dieser erreichbar ist.
            </p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="close">
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </fragment>
</template>

<script>
  import {Fragment} from 'vue-fragment';
  import DialogMixin from '../../mixins/dialog-mixin.js';


  export default {
    components: {
      Fragment
    },

    mixins: [
      DialogMixin
    ],

    data() {
      return {
        maxWidth: 750
      };
    }
  };
</script>

<style scoped>
  img.icon {
    cursor: pointer;
  }
</style>
