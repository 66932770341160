<template>
  <DocumentViewContainer
    :drawer-open="drawerOpen"
    :document="document"
  >
    <DocumentToolbarExtension
      v-if="!receipt_required"
      slot="toolbar"
      :document="document"
      delete
      done
      @delete="$refs.delete.open();"
      @done="$refs.done.open();"
    />
    <!--
      share
      @share="$refs.share.open();"
    -->

    <template #default="contentProps">
      <div v-if="receipt_required" class="px-6">
        <div v-if="document.receipt_required === 'SIGNATURE'">
          <div class="d-flex align-center">
            <v-icon class="text--signature">fas fa-signature</v-icon>
            <div>
              <v-card-title>
                Bitte bestätigen Sie den Erhalt des Dokuments mit Ihrer Unterschrift
              </v-card-title>
              <v-card-subtitle>
                Unterschreiben Sie in das nachstehende Feld
              </v-card-subtitle>
            </div>
          </div>
          <DocumentSignature class="mt-2" :document="document" @submit="refresh_document" />
        </div>
        <p v-else class="text--error">
          Nicht unterstützte Signatur "{{document.receipt_required}}".
        </p>
      </div>
      <template v-else-if="!receipt_required">
        <p>
          Wir wurden
          <template v-if="document.sender_address && document.sender_address.name">
            vom geprüften Absender <strong>"{{document.sender_address.name}}"</strong>
          </template>
          <template v-if="document.created">
            am <strong>{{document.created | moment('L [um] LT [Uhr]')}}</strong>
          </template>
          beauftragt {{$tc('documents.following_documents', document.files)}} zuzustellen.
        </p>

        <v-row>
          <v-col :cols="contentProps.columns ? '7': '12'">
            <DocumentFiles :document="document" @file-opened="_fileOpened" />

            <v-row v-if="document.description">
              <v-col>
                <h3 class="mb-6">Information vom Absender</h3>
                <v-card class="v-card--no-shadow">
                  <v-card-text class="text--primay-text">{{document.description}}</v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="document.shares && document.shares.length">
              <v-col>
                <DocumentShares :shares="document.shares" @delete="refresh_document() && refresh_documents()" />
              </v-col>
            </v-row>

            <v-row v-if="document.events && document.events.length">
              <v-col>
                <DocumentActivity :document="document" />
              </v-col>
            </v-row>
          </v-col>

          <v-col :cols="contentProps.columns ? '5': '12'">
            <h3 v-if="!contentProps.columns" class="mb-6">Absender</h3>
            <v-card class="sender-detail v-card--no-shadow px-6 py-6">
              <dl>
                <template v-if="document.source">
                  <dt class="mb-1">Quelle/Herkunft</dt>
                  <dd>{{document.source}}</dd>
                </template>

                <template v-if="document.sender_address && document.sender_address.name">
                  <dt class="mb-1" :class="{'mt-5': document.source}">Name</dt>
                  <dd>{{document.sender_address.name}}</dd>
                </template>

                <template v-if="document.sender_metadata && document.sender_metadata.description">
                  <dt class="mt-5 mb-1">Beschreibung</dt>
                  <dd>{{document.sender_metadata.description}}</dd>
                </template>

                <template v-if="document.sender_address && document.sender_address.address">
                  <dt class="mt-5 mb-1">Absender Adresse</dt>
                  <dd>
                    {{document.sender_address.address.street}}<br>
                    {{document.sender_address.address.postcode}} {{document.sender_address.address.city}}<br>
                    {{$t(`countries.${document.sender_address.address.country_code.toLowerCase()}`)}}
                  </dd>
                </template>

                <template v-if="document.sender_metadata && document.sender_metadata.contactPerson">
                  <dt class="mt-5 mb-1">Ihr Ansprechpartner</dt>
                  <dd>{{document.sender_metadata.contactPerson}}</dd>
                </template>

                <template v-if="document.sender_phone || document.sender_email || (document.sender_metadata && document.sender_metadata.website)">
                  <dt class="mt-5 mb-1">Kontaktinformationen</dt>
                  <dd>
                    <ul class="pl-0">
                      <li v-if="document.sender_phone" class="mb-1">
                        <v-icon small>far fa-phone-alt</v-icon>&nbsp;
                        <a :href="'tel:' + document.sender_phone.phone">{{document.sender_phone.phone}}</a>
                      </li>
                      <li v-if="document.sender_email" class="mb-1">
                        <v-icon small>far fa-envelope</v-icon>&nbsp;
                        <a :href="'mailto:' + document.sender_email.email">{{document.sender_email.email}}</a>
                      </li>
                      <li v-if="document.sender_metadata && document.sender_metadata.website" class="mb-1">
                        <v-icon small>far fa-globe-europe</v-icon>&nbsp;
                        <a :href="document.sender_metadata.website" target="_blank">{{_domain(document.sender_metadata.website)}}</a>
                      </li>
                    </ul>
                  </dd>
                </template>
              </dl>
              <div class="d-flex justify-center mt-5">
                <DocumentVerificationInfo />
              </div>
            </v-card>
          </v-col>
        </v-row>

        <DocumentDelete ref="delete" :document="document" />
        <!-- <DocumentShare ref="share" :document="document" @share="refresh" /> -->
        <DocumentDone ref="done" :document="document" @done="refresh_document() && refresh_documents()" />
      </template>
    </template>
  </DocumentViewContainer>
</template>

<script>
  import DocumentViewContainer from './DocumentViewContainer';
  import DocumentToolbarExtension from './DocumentToolbarExtension';
  import DocumentSignature from './DocumentSignature';
  import DocumentFiles from './DocumentFiles';
  import DocumentShares from './DocumentShares';
  import DocumentActivity from './DocumentActivity';
  import DocumentDelete from './DocumentDelete';
  // import DocumentShare from './DocumentShare';
  import DocumentDone from './DocumentDone';
  import DocumentVerificationInfo from './DocumentVerificationInfo';
  import watchDocumentVisibility from '../../utils/document-visibility';

  export default {
    name: 'DocumentViewReceived',

    components: {
      DocumentViewContainer,
      DocumentToolbarExtension,
      DocumentSignature,
      DocumentFiles,
      DocumentShares,
      DocumentActivity,
      DocumentDelete,
      // DocumentShare,
      DocumentDone,
      DocumentVerificationInfo
    },

    props: {
      drawerOpen: {
        type: Boolean,
        default: false
      },
      document: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        fileColumns: 1
      };
    },

    computed: {
      receipt_required() {
        return this.document.receipt_required !== 'NONE' && !this.document.receipt_date;
      }
    },

    watch: {
      document() {
        this._unwatch();
      }
    },

    destroyed() {
      this._unwatch();
    },

    methods: {
      refresh_document() {
        const document = this.$parent;
        document.refresh();
      },

      refresh_documents() {
        const document = this.$parent;
        const documents = document.$parent;
        documents.refresh();
      },

      refresh_inbox() {
        const document = this.$parent;
        const documents = document.$parent;
        const inbox = documents.$parent;
        inbox.refresh();
      },

      delete() {
        this.$refs.delete.open();
      },

      _domain(url) {
        try {
          return new URL(url).hostname;
        } catch (error) {
          return url;
        }
      },

      _unwatch() {
        if (typeof this._unwatchDocumentVisibility === 'function') {
          this._unwatchDocumentVisibility();
          this._unwatchDocumentVisibility = null;
        }
      },

      _fileOpened() {
        if (this._unwatchDocumentVisibility) {
          return;
        }

        // Refresh document if switched back to this tab.
        this._unwatchDocumentVisibility = watchDocumentVisibility((visible) => {
          if (visible) {
            this.refresh_document();
            this.refresh_documents();
            this.refresh_inbox();
          }
        });
      }
    }
  };
</script>
