<template>
  <v-row ref="files">
    <v-col v-for="(file, index) in document.files" :key="index" :cols="12 / columns" class="file">
      <v-card outlined>
        <v-card-text>
          <div class="text-center px-6 py-6">
            <a
              :href="`/api/inboxes/${document.inboxId}/documents/${document.id}/files/${file.id}`"
              :title="`${file.name} öffnen`"
              target="_blank"
              @click="$emit('file-opened')"
            >
              <v-badge bottom overlap icon="fal fa-download">
                <v-icon class="file-icon">{{_icon(file.name)}}</v-icon>
              </v-badge>
            </a>
          </div>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <v-card-text>
          <a
            :href="`/api/inboxes/${document.inboxId}/documents/${document.id}/files/${file.id}`"
            :title="`${file.name} öffnen`"
            target="_blank"
            @click="$emit('file-opened')"
          >
            <strong class="text-truncate text--primay-text d-block">{{file.name}}</strong>
          </a>
          <ul class="d-flex pl-0">
            <li class="text-truncate">{{_size(file.size)}}</li>
            <template v-if="showDownloadedStatus">
              <li class="mx-2">•</li>
              <li :class="{'text--info': !file.downloaded}">
                {{$t(`files.opened_${(!!file.downloaded).toString()}`)}}
              </li>
            </template>
          </ul>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import {ResizeSensor} from 'css-element-queries';
  import {icon, size} from '../../utils/file';

  export default {
    name: 'DocumentFiles',

    props: {
      document: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        columns: 1,
        minFileWidth: {
          type: Number,
          default: 245
        }
      };
    },

    computed: {
      showDownloadedStatus() {
        const {document} = this;
        if (!document) {
          return false;
        }
        if (document.status === 'RECEIVED') {
          return true;
        }
        if (document.status === 'SENT' && document.confirmation && document.confirmation.includes('downloaded')) {
          return true;
        }
        return false;
      }
    },

    async mounted() {
      // Wait until elements are created.
      await new Promise((resolve) => this.$nextTick(() => resolve()));

      const files = this.$refs.files;
      new ResizeSensor(files, () => {
        let columns = Math.floor(files.clientWidth / this.minFileWidth);
        if (columns >= 3) {
          columns = 3;
        }
        this.columns = columns;
      });
    },

    methods: {
      _icon(name) {
        return icon(name);
      },

      _size(bytes) {
        return size(bytes);
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/variables';

  // .file {
  //   max-width: 300px;
  //   margin: 0 auto;
  // }

  .file .file-icon {
    font-size: 6em;
  }

  .file a {
    color: $primary-text;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
</style>

<style scoped>
  .file >>> .v-badge__badge {
    inset: calc(100% - 20px) auto auto calc(100% - 20px) !important;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 6px;
  }

  .file >>> .v-badge__badge .v-icon {
    font-size: 25px;
  }
</style>
