import routes from 'xca-app-core/src/router/routes';
import ErrorPage from 'xca-app-core/src/views/Error';
import LandingPage from './views/LandingPage';
import Inboxes from './views/Inboxes';
import Inbox from './views/Inbox';
import InboxCreate from './views/InboxCreate';
import InboxUpdate from './views/InboxUpdate';
import Documents from './views/Documents';
import Document from './views/Document';
// import Preferences from './views/Preferences';


// Overwrite landing page.
const landingPageIndex = routes.findIndex((route) => route.path === '/');
routes[landingPageIndex].component = LandingPage;

// Remove about page.
const aboutPageIndex = routes.findIndex((route) => route.path === '/about');
routes.splice(aboutPageIndex, 1);

// Set title to ErrorPages
for (const route of routes) {
  if (route.component === ErrorPage) {
    if (!route.meta) {
      route.meta = {};
    }
    route.meta.title = 'Fehler';
  }
}

export default [
  {
    path: '/inboxes',
    name: 'inboxes',
    component: Inboxes,
    meta: {
      title: 'Postfächer',
      permissions: ['user']
    },
    children: [
      {
        path: '/inboxes/create',
        name: 'inbox-create',
        component: InboxCreate,
        meta: {
          title: 'Postfach erstellen'
        }
      },
      {
        path: '/inboxes/:inboxId',
        redirect: (to) => {
          return `/inbox/${to.params.inboxId}/update`;
        },
        children: [
          {
            path: '/inboxes/:inboxId/documents',
            redirect: (to) => {
              return `/inbox/${to.params.inboxId}/documents`;
            },
            children: [
              {
                path: '/inboxes/:inboxId/document/:documentId',
                redirect: (to) => {
                  return `/inbox/${to.params.inboxId}/document/${to.params.documentId}`;
                }
              }
            ]
          }
        ]
      },
      {
        path: '/inboxes/:inboxId/update',
        name: 'inbox-update',
        component: InboxUpdate,
        props: (route) => {
          const step = parseInt(route.query.step);
          return {step: step || 1};
        },
        meta: {
          title: 'Postfach Einstellungen'
        }
      }
    ]
  },
  {
    path: '/inbox/:inboxId',
    name: 'inbox',
    component: Inbox,
    meta: {
      title: 'Postfach',
      permissions: ['user']
    },
    redirect: (to) => {
      return `/inbox/${to.params.inboxId}/documents`;
    },
    children: [
      {
        path: '/inbox/:inboxId/documents',
        name: 'documents',
        component: Documents,
        props: (route) => {
          const page = parseInt(route.query.page);
          return {page: page || 1};
        },
        meta: {
          title: 'Dokumente'
        },
        children: [
          {
            path: '/inbox/:inboxId/document/:documentId',
            name: 'document',
            component: Document,
            meta: {
              title: 'Dokument'
            }
          }
        ]
      }
    ]
  },
  // {
  //   path: '/preferences',
  //   name: 'preferences',
  //   component: Preferences,
  //   meta: {
  //     title: 'Einstellungen',
  //     permissions: ['user']
  //   }
  // },
  ...routes
];
